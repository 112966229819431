import { Button, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import { getRole } from "../../../Core/utils/auth";
import TextEditor from "../../QuillTextEditor/TextEditor";
import { API } from "../../../Services/api-service";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import swal from "sweetalert";
import htmlParser from "html-react-parser";

const Instructions = ({
  questionData,
  sectionId,
  handleAnswers,
  setHasAnswered,
  setIconDisabled,
  clearAdminEditors,
  getSections,
  nextSection,
  parentQuestionId,
  handleOpenEnded,
  preview,
}) => {
  const classes = useStyles();
  const { exerciseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [instruction, setInstruction] = useState(questionData?.questionDesc);
  const [questionId, setQuestionId] = useState(questionData?.questionId);
  const [score, setScore] = useState(questionData?.score);
  const role = getRole();

  useEffect(() => {
    if (
      (role !== "admin" || preview) &&
      questionData?.questionType !== "Question Set"
    ) {
      if (questionData?.isQnResponded) {
        setIconDisabled(false);
      } else {
        setIconDisabled(true);
      }
      let ans = [{ viewed: 1 }];
      setHasAnswered(true);
      handleAnswers(questionData?.questionType, questionData?.questionId, ans);
      handleOpenEnded(false);
    }
  }, []);

  const handleChange = (value) => {
    setInstruction(value);
  };

  const saveInstructionQuestion = () => {
    setLoading(true);
    const urlAddn = questionId ? `/${questionId}` : "";
    API.post(`/${exerciseId}/questionnaire/${sectionId}/question${urlAddn}`, {
      type: parentQuestionId ? "Question Set" : "Instruction - Text",
      title: "Read the paragraph",
      description: instruction,
      score: score ? parseInt(score, 10) : 0,
      difficulty: "Easy",
    })
      .then((res) => {
        setLoading(false);
        if (res?.data?.questionId) {
          setQuestionId(res?.data?.questionId);
        }
        getSections(sectionId);
        toast.success("Question saved successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.msg || "Something went wrong please try again",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        console.log(err);
      });
  };

  const handleDeleteSection = () => {
    if (!sectionId) {
      return;
    }
    swal({
      title: `Delete Section?`,
      text: `Cannot be recovered once deleted.`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    })
      .then((isDeleted) => {
        if (isDeleted) {
          setLoading(true);
          API.delete(`${exerciseId}/questionnaire/section/${sectionId}`)
            .then((res) => {
              setLoading(false);
              toast.success(
                res?.data?.msg || "Something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              clearAdminEditors();
              getSections(nextSection);
            })
            .catch((err) => {
              setLoading(false);
              toast.error(
                err?.response?.data?.msg ||
                  "Something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              console.log(err);
            });
        }
      })
      .catch((err) => {});
  };

  return (
    <Grid
      className={`${classes.flex_row} ${classes.justifyCenter} ${classes.alignItemsCenter}`}
      style={{ height: "100%" }}
    >
      {role === "admin" && preview === false ? (
        <Grid
          className={`${classes.adminInstructionBox} ${classes.font14} ${classes.font400}`}
          style={{
            border: parentQuestionId ? "none" : "",
            width: parentQuestionId ? "100%" : "",
            height: parentQuestionId ? "95%" : "",
          }}
        >
          <Grid
            className={`${classes.flex_row} ${classes.justifyBetween} mt-1 mr-2`}
          >
            <Grid
              className={`${classes.flex_row} ${classes.justifyCenter} ${classes.alignItemsCenter}`}
              style={{ marginLeft: "20px" }}
            >
              Instructions
            </Grid>
            {!parentQuestionId && (
              <Button
                onClick={handleDeleteSection}
                className={`${classes.deleteSectionBtn}`}
                disabled={loading}
              >
                Delete Section <DeleteOutlineOutlinedIcon fontSize="small" />
              </Button>
            )}
          </Grid>
          <Grid className={`${classes.textEditorContainer}`}>
            <TextEditor handleChange={handleChange} answers={instruction} />
          </Grid>
          <Grid className="mt-3">
            {!parentQuestionId && (
              <Grid className={`${classes.instructionScoreContainer}`}>
                <Grid>Score</Grid>
                <input
                  type="number"
                  className={`${classes.inputUrlText} pl-2`}
                  name="input"
                  value={score}
                  onChange={(e) => setScore(e.target.value)}
                  placeholder="Enter score"
                  style={{ width: "100%", borderColor: "#ccc" }}
                />
              </Grid>
            )}
          </Grid>
          <Grid className={classes.saveInstructionBtnContainer}>
            <Button
              onClick={saveInstructionQuestion}
              className={`${classes.saveInstructionBtn}`}
              disabled={loading}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          className={`${classes.InstructionsAndQuestionBox} ${classes.font14} ${classes.font400}`}
        >
          {htmlParser(questionData?.questionDesc)}
        </Grid>
      )}
    </Grid>
  );
};

export default Instructions;
