import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Grid,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, FormControl, Select } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import Pagination from "@material-ui/lab/Pagination";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";
import { API } from "../../Services/api-service";
import { Spinner } from "react-bootstrap";
import { useCookies } from "react-cookie";
import AssignExercisePopup from "./AssignExercisePopup";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleExercise } from "./exerciseHelper";
import { toast } from "react-toastify";
import { Tooltip, withStyles } from "@material-ui/core";

toast.configure();

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "#569090",
    border: "0.2px solid #CCCCCC",
    borderRadius: "8px",
    fontSize: 14,
    padding: "8px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  rootTable: {
    fontFamily: "Inter",
  },
  tableHeader: {
    backgroundColor: "#FAFBFF",
    borderBottom: "1px solid #EBF1FF",
  },
  tableHeaderText: {
    color: "#2761BA",
    fontWeight: "bold",
  },
  tableCell: {
    color: "#7A7A7A",
    borderBottom: "1px solid #EBF1FF",
    height: "49px",
  },
  statusPending: {
    color: "#D33636",
  },
  statusCompleted: {
    color: "#53A263",
  },
  statusTobeAssign: {
    color: "#E98451",
    cursor: "pointer",
  },
  viewIcon: {
    color: "#E98451",
  },
  tableContainer: {
    maxHeight: "400px", // Adjust the height as needed for scrollability
  },
  goBack: {
    fontWeight: 500,
    fontSize: "14px",
    cursor: "pointer",
  },
  searchFieldContainer: {
    background: theme.palette.background.studentProgressBg,
    border: theme.palette.studentProgressBorder,
    borderRadius: "4px",
    height: "40px",
    padding: "0 5px 0 5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#A0A0A0",
  },
  searchField: {
    border: "none",
    width: "100%",
    outline: "none",
    fontSize: "14px",
    fontWeight: 400,
    background: theme.palette.background.studentProgressBg,
    color: "#A0A0A0",
  },
  searchBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterText: {
    fontWeight: 500,
    fontSize: "14px",
  },
  filterBox: {
    display: "flex",
    alignItems: "center",
  },
  conceptText: {
    fontWeight: 500,
    fontSize: "16px",
    color: theme.palette.trackerHeading,
  },
  assignExerciseCard: {
    width: "max-content",
    height: "40px",
    padding: "10px 20px",
    borderRadius: "4px",
    background: "#E98451",
    marginRight: "15px",
    color: "#FFF",
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Inter",
    "&:hover": {
      background: "#E98451",
      color: "#FFF",
    },
  },
  loaderRoot: {
    width: "100%",
    minHeight: "473px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    "& .MuiPaginationItem-root": {
      border: "1px solid #C4C0C0",
      color: "#7A7A7A",
      "&:hover": {
        backgroundColor: "#E98451",
        color: "#FFF",
        border: "1px solid #E98451",
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#E98451",
      color: "#FFF",
      border: "1px solid #E98451",
      "&:hover": {
        backgroundColor: "#E98451",
        color: "#FFF",
        border: "1px solid #E98451",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      color: "#7A7A7A",
    },
  },
  rootCheck: {
    color: "#7A7A7A",
    "&$checked": {
      color: "#E98451",
    },
  },
  checked: {},
}));
const header = ["Exercise", "Catalog", "Assessment", "Status", "View"];

const TableComponent = ({
  apiResponse,
  onViewClick,
  topic,
  status,
  setStatus,
  assigned,
  setAssigned,
  exerciseLoader,
  selectedStudent,
  currentPage,
  totalPages,
  setCurrentPage,
  getExerciseReport,
}) => {
  const classes = useStyles();
  const isDarkMode = useSelector(selectDarkMode);
  const history = useHistory();
  const [assignedLoader, setAssignedLoader] = useState(false);
  const [cookies, setCookie] = useCookies([
    "catalogueId",
    "exerciseId",
    "role",
    "location",
    "fileId",
    "fileName",
    "type",
  ]);

  const handleChange = (event) => {
    setStatus(event.target.value);
    setCurrentPage(1)
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [filteredData, setFilteredData] = useState(apiResponse);
  const [exercise, setExercise] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [assignStatus, setAssignStatus] = useState({});

  const toggleAssignCheckbox = (exercise) => {
    setAssignStatus((prevState) => ({
      ...prevState,
      [exercise.exerciseId]: !prevState[exercise.exerciseId],
    }));

    if (!assignStatus[exercise.exerciseId]) {
      setSelectedExercises((prevSelected) => [...prevSelected, exercise]);
    } else {
      setSelectedExercises((prevSelected) =>
        prevSelected.filter(
          (selected) => selected.exerciseId !== exercise.exerciseId
        )
      );
    }
  };

  const handleSelectExercise = (exercise) => {
    setSelectedExercises((prevSelected) => {
      const isSelected = prevSelected.some(
        (selected) => selected.exerciseId === exercise.exerciseId
      );
      if (isSelected) {
        toggleAssignCheckbox(exercise);
        return prevSelected.filter(
          (selected) => selected.exerciseId !== exercise.exerciseId
        );
      }
      return prevSelected;
    });
  };

  useEffect(() => {
    handleSearch();
  }, [exercise, apiResponse]);

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const closePopup = () => {
    setOpenPopup(false);
  };
  useEffect(()=>{
    setStatus("All")
  },[])

  const handleSearch = () => {
    if (exercise) {
      const filtered = apiResponse.filter(
        (item) =>
          item.exerciseTitle.toLowerCase().includes(exercise.toLowerCase()) ||
          item.catalogueTitle.toLowerCase().includes(exercise.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(apiResponse);
    }
  };

  const assignExercise = () => {
    setAssignedLoader(true);
    API.post(`/exercises/assignment/assign`, {
      singleStudent: true,
      studentId: selectedStudent,
      exercises: selectedExercises,
    })
      .then((res) => {
        setAssigned(true);
        getExerciseReport();
        setAssignedLoader(false);
        setSelectedExercises([]);
      })
      .catch((err) => {
        console.log(err, "err");
        setAssignedLoader(false);
        toast.error(err.response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const filter = ["All", "Completed", "Pending", "Unassigned"];

  return (
    <Grid className={classes.rootTable}>
      <Grid className={classes.searchBox}>
        <Grid
          className={classes.goBack}
          onClick={onViewClick}
          style={{
            color: isDarkMode ? "#D0CBC8" : "#7A7A7A",
          }}
        >
          <ArrowBackOutlinedIcon
            fontSize="small"
            style={{ height: "16px", width: "16px", marginRight: "4px" }}
          />{" "}
          Go Back
        </Grid>
        <Grid className={classes.searchBox}>
          {selectedExercises.length > 0 ? (
            <Button
              className={classes.assignExerciseCard}
              onClick={assignExercise}
              disabled={assignedLoader}
            >
              {assignedLoader
                ? "loading..."
                : `Assign Exercises (${selectedExercises.length})`}
            </Button>
          ) : (
            ""
          )}
          <Grid
            className={classes.searchFieldContainer}
            style={{ border: isDarkMode && "1px solid #292929" }}
          >
            <input
              placeholder="Search exercises"
              className={classes.searchField}
              value={exercise}
              onChange={(e) => setExercise(e.target.value)}
              onKeyDown={handleSearchEnter}
            />
            <SearchOutlinedIcon
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={handleSearch}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={`${classes.searchBox} mt-2 mb-3`}>
        <Grid className={classes.conceptText}>
          Concept - {topic?.topicName || "-"}
        </Grid>
        <Grid className={classes.filterBox}>
          <span
            className={`${classes.filterText}`}
            style={{
              color: isDarkMode ? "#ECEAE9" : "#7A7A7A",
            }}
          >
            Filter by Status:
          </span>
          <FormControl>
            <Select
              labelId="grade-label"
              value={status}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <span style={{ fontStyle: "normal" }}>Select status</span>
                  );
                }
                return selected;
              }}
              inputProps={{ "aria-label": "Select status" }}
              className={classes.selectDropdown}
              sx={{
                color: !isDarkMode ? "#292929" : "#D0CBC8",
                width: "max-content",
                border: "none",
                height: "24px",
                fontSize: "14px",
                fontWeight: 500,
                "& .MuiSvgIcon-root": {
                  color: !isDarkMode ? "#292929" : "#D0CBC8",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: !isDarkMode && "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: !isDarkMode && "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: !isDarkMode && "transparent",
                },
                "&.Mui-focused": {
                  outline: "none",
                  boxShadow: "none",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: isDarkMode ? "#414141" : "#FFFFFF",
                    "& .MuiMenuItem-root": {
                      color: isDarkMode ? "#D0CBC8" : "#292929",
                      "&:hover": {
                        backgroundColor: isDarkMode && "#1E1E1E",
                      },
                    },
                  },
                },
              }}
            >
              {filter.map((data, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={data}
                    sx={{
                      color: !isDarkMode ? "#292929" : "#D0CBC8",
                      fontSize: "12px",
                    }}
                  >
                    {data}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer
        style={{
          marginBottom: "20px",
          maxHeight: "80vh",
          overflow: "auto",
          fontFamily: "Inter",
        }}
      >
        {exerciseLoader ? (
          <Grid className={classes.loaderRoot}>
            <Spinner animation="border" variant="grey" />
          </Grid>
        ) : filteredData.length > 0 ? (
          <Table stickyHeader>
            {/* Table Header */}
            <TableHead>
              <TableRow style={{ backgroundColor: "#FAFBFF" }}>
                {header.map((headerName, index) => (
                  <TableCell
                    key={index}
                    align={index == 0 || index == 1 ? "left" : "center"}
                    style={{
                      backgroundColor: isDarkMode ? "#2E2824" : "#FAFBFF",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      borderTop: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      fontWeight: "bold",
                      color: isDarkMode ? "#D0CBC8" : "#2761BA",
                      fontFamily: "Inter",
                    }}
                  >
                    {headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody style={{ color: isDarkMode ? "#ECEAE9" : "#7A7A7A" }}>
              {filteredData?.map((row, idx) => (
                <TableRow key={row.exerciseId}>
                  <TableCell
                    align="left"
                    style={{
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      color: isDarkMode ? "#ECEAE9" : "#7A7A7A",
                      fontFamily: "Inter",
                    }}
                  >
                    <Grid>
                      {row.exerciseTitle?.length > 35 ? (
                        <CustomTooltip title={row.exerciseTitle}>
                          <Grid>{`${row.exerciseTitle.slice(0, 35)}...`}</Grid>
                        </CustomTooltip>
                      ) : (
                        <Grid>{row?.exerciseTitle}</Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      padding: "10px",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      color: isDarkMode ? "#ECEAE9" : "#7A7A7A",
                      fontFamily: "Inter",
                    }}
                  >
                    <Grid>
                      {row.catalogueTitle?.length > 22 ? (
                        <CustomTooltip title={row.catalogueTitle}>
                          <Grid>{`${row.catalogueTitle.slice(0, 22)}...`}</Grid>
                        </CustomTooltip>
                      ) : (
                        <Grid>{row?.catalogueTitle}</Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      padding: "10px",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      color: isDarkMode ? "#ECEAE9" : "#7A7A7A",
                      fontFamily: "Inter",
                    }}
                  >
                    {row.typeId == 0 ? "No" : "Yes"}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      padding: "10px",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      fontFamily: "Inter",
                    }}
                  >
                    {row.status === "ToBeAssigned" &&
                    !assignStatus[row.exerciseId] ? (
                      <Grid
                        className={classes.statusTobeAssign}
                        onClick={() => toggleAssignCheckbox(row)}
                      >
                        Assign <AddCircleOutlineOutlinedIcon fontSize="small" />
                      </Grid>
                    ) : row.status === "ToBeAssigned" &&
                      assignStatus[row.exerciseId] ? (
                      <Checkbox
                        checked={selectedExercises.some(
                          (selected) => selected.exerciseId === row.exerciseId
                        )}
                        onChange={() => handleSelectExercise(row)}
                        classes={{
                          root: classes.rootCheck,
                          checked: classes.checked,
                        }}
                      />
                    ) : (
                      <Grid
                        className={
                          row.status === "Pending"
                            ? classes.statusPending
                            : classes.statusCompleted
                        }
                      >
                        {row.status}
                      </Grid>
                    )}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: "#FF712D",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      fontSize: "14px",
                    }}
                  >
                    {row.completed ? (
                      <OpenInNewIcon
                        onClick={() => {
                          if (row.completed) {
                            handleExercise(
                              row.submissionId,
                              row.exerciseId,
                              row.catalogId,
                              row.disable,
                              row.category,
                              setCookie,
                              "exercise"
                            );
                          }
                        }}
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    ) : (
                      <OpenInNewIcon
                        style={{
                          fontSize: "18px",
                          color: "#CCCCCC",
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <center>
            <Grid
              style={{
                padding: "20px",
                color: "#AB5C5C",
              }}
            >
              No Data Found
            </Grid>
          </center>
        )}
      </TableContainer>
      {totalPages > 1 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            marginTop: "15px",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            boundaryCount={1}
            siblingCount={3}
            color="primary"
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
            hideNextButton={true}
            hidePrevButton={true}
            classes={{ root: classes.root }}
          />
        </div>
      )}
      {assigned && (
        <AssignExercisePopup
          open={assigned}
          onClose={closePopup}
          assigned={assigned}
          setAssigned={setAssigned}
          location="tracker-2"
        />
      )}
    </Grid>
  );
};

export default TableComponent;
