import React, { useState, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Slider,
  Typography,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  uploadImage: {
    display: "flex",
    justifyContent: "center",
    width: "200px !important",
    textTransform: "none",
    background: "#FF712D !important",
    color: "#FFF",
    height: "40px",
    "&:hover": {
      background: "#FF712D",
      color: "#FFF",
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center !important",
  },

  saveBtn: {
    width: "143px",
    textTransform: "none",
    background: "#FF712D !important",
    color: "#FFF",
    height: "40px",
    "&:hover": {
      background: "#FF712D",
      color: "#FFF",
    },
  },

  cancelBtn: {
    width: "143px",
    textTransform: "none",
    color: "#FF712D !important",
    height: "40px",
    "&:hover": {
      color: "#FF712D",
    },
  },
}));

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageUploader = ({
  imageCropperModal,
  closeCropperModal,
  finalImage,
  handleImageSave,
}) => {
  // Image editing states
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [scale, setScale] = useState(1);
  const [aspect, setAspect] = useState(16 / 9);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });

  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const classes = useStyles();

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setImgSize({ width, height });
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const handleAspectChange = (event, newAspect) => {
    if (newAspect !== null) {
      setAspect(newAspect);
      if (imgSize.width && imgSize.height) {
        setCrop(centerAspectCrop(imgSize.width, imgSize.height, newAspect));
      }
    } else {
      setAspect(null);
      // For free aspect ratio, maintain the current crop center but remove aspect constraint
      if (crop) {
        const centerX = crop.x + crop.width / 2;
        const centerY = crop.y + crop.height / 2;
        setCrop({
          unit: "%",
          x: centerX - 45,
          y: centerY - 45,
          width: 90,
          height: 90,
        });
      }
    }
  };

  const triggerFileInput = () => {
    inputRef.current.click();
  };

  const handleSave = async () => {
    if (!completedCrop || !imgRef.current) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

    canvas.width = completedCrop.width * scaleX;
    canvas.height = completedCrop.height * scaleY;

    ctx.drawImage(
      imgRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    // const croppedImageUrl = canvas.toDataURL("image/jpeg");
    // handleImageSave(croppedImageUrl);
    // closeCropperModal(false);

    // canvas.toBlob((blob) => {
    //   if (!blob) return;
    //   const imageUrl = URL.createObjectURL(blob);
    //   handleImageSave(imageUrl);
    //   closeCropperModal(false);
    // });
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          console.error("Canvas to Blob conversion failed");
          return;
        }
        const file = new File([blob], "cropped-image.png", {
          type: "image/png",
        });
        const croppedImageUrl = URL.createObjectURL(blob);
        handleImageSave({
          displayUrl: croppedImageUrl,
          file: file,
        });

        closeCropperModal(false);
      },
      "image/png",
      1
    );
  };

  const handleClose = () => {
    closeCropperModal(false);
    setImgSrc("");
    setCrop(undefined);
    setScale(1);
    setCompletedCrop(null);
    setImgSize({ width: 0, height: 0 });
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", p: 2 }}>
      <Dialog
        open={imageCropperModal}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          Upload & Edit Image
        </DialogTitle>
        <DialogContent>
          <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              ref={inputRef}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              onClick={triggerFileInput}
              startIcon={<CloudUploadIcon />}
              fullWidth
              className={classes.uploadImage}
            >
              Upload Image
            </Button>
          </Box>

          {imgSrc && (
            <Box sx={{ mt: 2, space: 2 }}>
              {/* <Typography gutterBottom>Scale: {scale.toFixed(2)}x</Typography>
              <Slider
                value={scale}
                min={0.1}
                max={3}
                step={0.1}
                onChange={(_, value) => setScale(value)}
                sx={{ mb: 3 }}
              /> */}

              <Box sx={{ mb: 2 }}>
                <ToggleButtonGroup
                  value={aspect}
                  exclusive
                  onChange={handleAspectChange}
                  size="small"
                >
                  <ToggleButton value={16 / 9}>16:9</ToggleButton>
                  <ToggleButton value={4 / 3}>4:3</ToggleButton>
                  <ToggleButton value={1}>1:1</ToggleButton>
                  <ToggleButton value={null}>Free</ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box
                sx={{
                  overflow: "hidden",
                  "& .ReactCrop": {
                    maxWidth: "100%",
                    background: "#f0f0f0",
                  },
                }}
              >
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    style={{
                      transform: `scale(${scale})`,
                      maxWidth: "100%",
                      height: "auto",
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </Box>
            </Box>
          )}
        </DialogContent>

        <DialogActions className={classes.actionsWrapper}>
          <Button onClick={handleClose} className={`${classes.cancelBtn}`}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={!completedCrop}
            className={`${classes.saveBtn}`}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImageUploader;
