import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import { Grid, makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import HomeIcon from "@material-ui/icons/Home";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import CodeIcon from "@material-ui/icons/Code";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import Menu from "@material-ui/core/Menu";
import { decrypt } from "../../Core/utils/others";
import { useLocation } from "react-router-dom";
import { getRole } from "../../Core/utils/auth";
import { useHistory } from "react-router-dom";
import TerminalIcon from "@mui/icons-material/Terminal";
import AssessmentIcon from "@material-ui/icons/Assessment";
import swal from "sweetalert";
import SandboxLogo from "../../assets/images/sandboxLogoNav.svg";
import SandboxText from "../../assets/images/SandboxText.svg";
import wave from "../../assets/images/wave.png";
import jwt_decode from "jwt-decode";
import saveGTMEvents from "../../SharedComponents/gtm";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import admin from "../../assets/images/admin.png";
import student from "../../assets/images/student.png";
import guest from "../../assets/images/guest.png";
import mentor from "../../assets/images/mentor.png";
import defaultUser from "../../assets/images/defaultUser.png";
import { SpanRecorder } from "@sentry/tracing/dist/span";
import { useSelector, useDispatch } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";
import dark_mode from "../../assets/images/dark_mode_icon.svg";
import light_mode from "../../assets/images/light_mode_icon.svg";
import noahFull from "../../assets/images/noah_full.png";
import { ToggleBar } from "../SidebarComponent/ToggleBar";
import Cookies from "js-cookie";
import SandboxTextWhite from "../../assets/images/SandboxTextWhite.svg";
import Sparkle from "../../assets/images/stars_icon.png";
import NewUpdate from "../Home/NewUpdateModal";
import { API } from "../../Services/api-service";
import { navigateToQuiz } from "../Home/NoahBanner/helper/navigation";

const role = getRole();
const useStyles = makeStyles((theme) => ({
  headerRoot: {
    // height: getRole() === "guest" ? "" : "103px",
    display: "flex",
    alignItems: getRole() === "guest" ? "flex-start" : "center",
    // marginTop: 25,
    justifyContent: "space-between",
    background: theme.palette.background.background0,
    color: theme.palette.heading,
  },
  root: {
    maxWidth: "100%",
    marginTop: 20,
    borderRadius: 25,
    padding: 10,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
  },
  "MuiCardActions-root": {
    marginBottom: 20,
  },
  title: {
    fontWeight: 600,
    color: theme.palette.heading,
  },
  trackerHeading: {
    fontWeight: 700,
    color: theme.palette.trackerHeading,
    fontFamily: "Inter",
    fontSize: "18px",
  },
  subTitle: {
    color: theme.palette.body,
  },
  trackerText: {
    color: theme.palette.trackerTextClr,
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter",
    marginTop: "8px",
  },
  text: {
    fontSize: "16px !important",
  },
  welcometxt: {
    color: theme.palette.body,
  },
  mHeaderRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    background: theme.palette.background.background1,
    color: theme.palette.heading,
  },
  small: {
    width: 35,
    height: 35,
  },
  userType: {
    width: "max-content",
    height: "65px",
    background: "#FFF1EB",
    borderRadius: "28px",
    display: "flex",
    padding: "14px 18px 18px 15px",
    justifyContent: "space-around",
    gap: "5px",
  },
  names: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
  },
  guestmHeader: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    display: "flex",
  },
  drawerIcon: {
    color: theme.palette.heading,
  },
  drawer: {
    background: theme.palette.background.background2,
    border: `1px solid ${theme.palette.border2}`,
  },
  sidebarImg: {
    width: "20px",
    height: "20px",
  },
  userIconWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "70px",
  },
  profileContainer: {
    borderTop: `1px solid ${theme.palette.profileBorder}`,
    background: theme.palette.proofileBackground,
  },
  userContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "12px 20px",
  },
  userWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
  },
  userPic: {
    height: "36px",
    width: "36px",
    marginRight: "8px",
    borderRadius: "50%",
  },
  userName: {
    color: theme.palette.body,
    fontSize: "14px",
    fontWeight: "500",
    marginLeft: "10px",
  },
  userRole: {
    width: "100%",
    padding: "6px 0px",
    borderRadius: "4px",
    background: theme.palette.background.roleBackground,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.roleColor,
    marginBottom: "20px",
    fontSize: "14px",
  },
  logoutContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 12px",
    cursor: "pointer",
  },
  logoutText: {
    fontSize: "14px",
    color: "#E35D5D",
    fontWeight: "700",
    marginRight: "5px",
  },
  logoutIcon: {
    padding: "0px",
    color: "#E35D5D",
  },
  sandboxLogo: {
    height: "60px",
    width: "60px",
    objectFir: "contain",
    [theme.breakpoints.down("md")]: {
      height: "52px",
      width: "52px",
    },
  },
  sandboxText: {
    height: "36px",
    width: "122px",
    [theme.breakpoints.down("sm")]: {
      height: "22px",
      width: "125px",
    },
  },
  toggleContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
  },

  exerciseHeader: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-10px",
      marginRight: "-10px",
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.background.background0,
    color: theme.palette.heading,
    boxShadow: "0px 4px 8px 0px #00000014",
    padding: "4px 0px 4px 14px",
    marginLeft: "-32px",
    marginRight: "-32px",
  },

  exerciseLogo: {
    height: "32px",
    width: "32px",
  },

  exerciseTitle: {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#000000",
  },
}));

const HeaderBar = ({ handleToggle }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const roles = getRole();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const isDarkMode = useSelector(selectDarkMode);
  const [userData, setUserData] = useState({
    userName: "",
    profileImage: "",
    initial: "",
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [newUpdate, setNewUpdate] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const open = Boolean(anchorEl);
  const sandboxUser = decrypt(localStorage.getItem("sandbox_user"));

  const EXERCISE_USERS = process.env.REACT_APP_EXERCISE_USERS
    ? process.env.REACT_APP_EXERCISE_USERS.split(",")
    : [];

  const userInfo = jwt_decode(sandboxUser.ACCESS_TOKEN).userInfo;
  const ASSETS_URL = `${process.env.REACT_APP_SANDBOX_ASSETS_URL}`;

  useEffect(() => {
    if (localStorage.getItem("sandbox_user")) {
      let local = decrypt(localStorage.getItem("sandbox_user"));
      const obj = {
        userName: local.userName ? local.userName : local.email,
        profileImage: local.profilePicUrl,
        initial: local.userName
          ? local.userName.charAt(0)
          : local.email.charAt(0).toUpperCase(),
      };
      setUserData(obj);
    }
    // getUpdates();
  }, []);

  const getUpdates = async () => {
    try {
      const response = await API.get("/newUpdates?updateName=darkMode");
      if (response.data && !response.data?.isViewed) setNewUpdate(true);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToWorkBench = () => {
    history.push("/workbench");
  };

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer((prevState) => !prevState);
  };
  const logout = () => {
    swal({
      title: "Logout",
      text: "Do you really want to logout?",
      buttons: ["No", "Yes, Logout"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then((logout) => {
      handleToggle();
      if (logout) {
        Cookies.remove("AuthorizationToken", { domain: ".codeyoung.com" });
        Cookies.remove("homeWorkCatalogId", { domain: ".codeyoung.com" });
        Cookies.remove("homeWorkViewAll", { domain: ".codeyoung.com" });
        Cookies.remove("homeWorkExerciseId", { domain: ".codeyoung.com" });
        Cookies.remove("userName", { domain: ".codeyoung.com" });
        Cookies.remove("userId", { domain: ".codeyoung.com" });
        Cookies.remove("grade", { domain: ".codeyoung.com" });
        Cookies.remove("tracker", { domain: ".codeyoung.com" });
        if (localStorage.getItem("darkMode")) {
          localStorage.removeItem("darkMode");
        }
        localStorage.removeItem("sandbox_user");
        localStorage.removeItem("sud");
        window.location.replace("/");
      }
    });
  };
  const navigateTo = (to, text) => {
    const userDataNoah = JSON.parse(localStorage.getItem("userData"));
    // if (role === "guest" && to === "/files") {
    //   setUpgradeModal(true);
    // } else {
    if (to !== "/home") {
      const action =
        to === "/workbench"
          ? "workbench_clicked"
          : to === "/files"
          ? "files_clicked"
          : to === "/exercises"
          ? "exercises_tab"
          : "learning_clicked";
      saveGTMEvents(action, role, sandboxUser.email);
    }
    if (text == "Noah") {
      navigateToQuiz(userDataNoah);
    } else {
      history.push(to);
    }
    setOpenDrawer(!openDrawer);
    // }
  };

  const handleTryNow = () => {
    setOpenDrawer(true);
    API.post("/newUpdates", {
      updateName: "darkMode",
    })
      .then((res) => {
        setNewUpdate(false);
      })
      .catch((e) => {
        console.error(e);
        setNewUpdate(false);
      });
  };

  const routeItems = [
    {
      text: "Home",
      to: "/home",
      icon: location.pathname.includes("/home")
        ? `${ASSETS_URL}home_active.svg`
        : isDarkMode
        ? `${ASSETS_URL}home_passive_dark.svg`
        : `${ASSETS_URL}home_passive.svg`,
    },
    {
      text: "Workbench",
      to: "/workbench",
      icon: location.pathname.includes("/workbench")
        ? `${ASSETS_URL}workbench_active.svg`
        : isDarkMode
        ? `${ASSETS_URL}workbench_passive_dark.svg`
        : `${ASSETS_URL}workbench_passive.svg`,
    },
    {
      text: "Files",
      to: "/files",
      icon: location.pathname.includes("/files")
        ? `${ASSETS_URL}files_active.svg`
        : isDarkMode
        ? `${ASSETS_URL}files_passive_dark.svg`
        : `${ASSETS_URL}files_passive.svg`,
    },
    {
      text: "Learning Content",
      to: "/learningContent",
      icon: location.pathname.includes("/learningContent")
        ? `${ASSETS_URL}resource_active.svg`
        : isDarkMode
        ? `${ASSETS_URL}resource_passive_dark.svg`
        : `${ASSETS_URL}resource_passive.svg`,
    },
    {
      text: "Exercises",
      to: "/exercises",
      icon: location.pathname.includes("/exercises")
        ? `${ASSETS_URL}exercise_active.svg`
        : isDarkMode
        ? `${ASSETS_URL}exercise_passive_dark.svg`
        : `${ASSETS_URL}exercise_passive.svg`,
    },
    {
      text: "Noah",
      to: "/",
      icon: noahFull,
    },
  ];

  const exculdeUrl =
    location.pathname.includes("workbench") ||
    location.pathname.includes("maths");

  return (
    <>
      {newUpdate && !isBigScreen && (
        <NewUpdate open={true} handleClose={handleTryNow} />
      )}
      {!matches ? (
        !exculdeUrl && (
          <div
            className={classes.headerRoot}
            style={{
              marginTop: !location.pathname.includes("exercises") && "25px",
            }}
          >
            <div style={{ width: "100%" }}>
              {location.pathname.includes("tracker") && (
                <>
                  <Grid className={classes.trackerHeading}>
                    Exercise Tracker
                  </Grid>
                  <Grid className={classes.trackerText}>
                    Track and monitor your student's exercises here
                  </Grid>
                </>
              )}
              {location.pathname.includes("files") && (
                <>
                  <Typography variant="h5" className={classes.title}>
                    File Collection
                  </Typography>
                  <Typography variant="subtitle1" className={classes.subTitle}>
                    All of your code files are saved here
                  </Typography>
                </>
              )}
              {location.pathname.includes("learningContent") && (
                <>
                  <Typography variant="h5" className={classes.title}>
                    Learning Content
                  </Typography>
                  <Typography variant="subtitle1" className={classes.subTitle}>
                    Study material for better learning and understanding in your
                    course
                  </Typography>
                </>
              )}
              {roles != "guest" && location.pathname.includes("exercises") && (
                <>
                  {/* <Typography variant="h5" style={{ fontWeight: 600 }}>
                    Exercises
                  </Typography> */}
                  {/* {roles != "guest" && (
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#959595" }}
                    >
                      Practice and enhance your learning with interactive
                      exercises
                    </Typography>
                  )} */}
                </>
              )}
              {location.pathname.includes("whiteboard") && (
                <>
                  <Typography variant="h5" className={classes.title}>
                    Whiteboard
                  </Typography>
                </>
              )}
            </div>
          </div>
        )
      ) : (
        <>
          <div
            className={
              location.pathname.includes("sections")
                ? classes.exerciseHeader
                : classes.mHeaderRoot
            }
          >
            {role != "guest" && (
              <div>
                <img
                  src={SandboxLogo}
                  alt="Sandbox Logo"
                  className={
                    location.pathname.includes("sections")
                      ? classes.exerciseLogo
                      : classes.sandboxLogo
                  }
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {location.pathname.includes("home") && (
                <Typography
                  variant="h4"
                  style={{
                    fontSize: matchesXS ? "28px" : "",
                    fontWeight: matchesXS ? "600" : "",
                  }}
                >
                  Home
                </Typography>
              )}
              {location.pathname.includes("workbench") && (
                <Typography
                  variant="h4"
                  style={{
                    fontSize: matchesXS ? "28px" : "",
                    fontWeight: matchesXS ? "600" : "",
                  }}
                  className={classes.title}
                >
                  {" "}
                  Workbench
                </Typography>
              )}
              {location.pathname.includes("files") && (
                <>
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: matchesXS ? "28px" : "",
                      fontWeight: matchesXS ? "600" : "",
                    }}
                    className={classes.title}
                  >
                    File Collection
                  </Typography>
                </>
              )}
              {location.pathname.includes("learningContent") && (
                <>
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: matchesXS ? "28px" : "",
                      fontWeight: matchesXS ? "600" : "",
                    }}
                    className={classes.title}
                  >
                    Learning Content
                  </Typography>
                </>
              )}
              {location.pathname.includes("tracker") && (
                <>
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: matchesXS ? "28px" : "",
                      fontWeight: matchesXS ? "600" : "",
                    }}
                    className={classes.title}
                  >
                    Tracker
                  </Typography>
                </>
              )}
              {location.pathname.includes("exercises") && (
                <>
                  {location.pathname.includes("sections") ? (
                    <div className={classes.exerciseTitle}>Exercises</div>
                  ) : (
                    <Typography variant="h5" style={{ fontWeight: 600 }}>
                      Exercises
                    </Typography>
                  )}
                </>
              )}
            </div>
            <div>
              <IconButton onClick={toggleDrawer}>
                <MenuIcon
                  fontSize={
                    location.pathname.includes("sections") ? "default" : "large"
                  }
                  className={classes.drawerIcon}
                />
              </IconButton>
              <Drawer
                anchor="right"
                open={openDrawer}
                onClose={toggleDrawer}
                className={clsx(classes.drawer)}
                classes={{
                  paper: clsx(classes.drawer),
                }}
                // onOpen={toggleDrawer(true)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div>
                    <List>
                      <ListItem
                        style={{ paddingTop: "0px", paddingBottom: "0px" }}
                      >
                        <ListItemIcon
                          style={{
                            justifyContent: "center",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                        >
                          <img
                            src={SandboxLogo}
                            alt="Sand Box"
                            className={classes.sandboxLogo}
                          />
                        </ListItemIcon>
                        <ListItemIcon>
                          <img
                            src={isDarkMode ? SandboxTextWhite : SandboxText}
                            alt="Sand Box"
                            className={classes.sandboxText}
                          />
                        </ListItemIcon>
                      </ListItem>
                      <Divider />

                      {openDrawer && (
                        <div className={classes.toggleContainer}>
                          {
                            <span
                              style={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "17px",
                                letterSpacing: "0em",
                                color: isDarkMode ? "#847871" : "#569090",
                              }}
                            >
                              {isDarkMode ? "Dark Mode" : "Light Mode"}
                            </span>
                          }

                          <button
                            onClick={handleToggle}
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            {" "}
                            <ToggleBar theme={theme} />
                          </button>
                        </div>
                      )}
                    </List>
                    <Divider />
                  </div>
                  <div>
                    <List>
                      {routeItems.map((route, index) => (
                        <ListItem
                          button
                          key={route.text}
                          onClick={() => navigateTo(route.to, route.text)}
                          className={classes.listItemContainer}
                          style={{
                            color: location.pathname.includes(route.to)
                              ? theme.palette.highlightColor1
                              : isDarkMode
                              ? theme.palette.caption1
                              : theme.palette.caption,
                            background:
                              location.pathname.includes(route.to) &&
                              route.text != "Noah" &&
                              !isDarkMode
                                ? theme.palette.background.highlightBackground
                                : isDarkMode
                                ? theme.palette.background.background2
                                : theme.palette.background.background0,
                            paddingLeft: 18,
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            marginTop: "20px",
                            marginBottom:
                              index === routeItems.length - 1 && "20px",
                          }}
                        >
                          <img
                            src={route.icon}
                            alt={route.icon}
                            className={classes.sidebarImg}
                            style={{
                              height: route.text == "Noah" && "24px",
                              width: route.text == "Noah" && "24px",
                            }}
                          />
                          <ListItemText
                            style={{
                              fontSize: "14px",
                              marginLeft: "15px",
                              background:
                                route.text == "Noah" &&
                                "linear-gradient(71.91deg, #4186B4 23.13%, #FFD452 106.05%)",
                              WebkitBackgroundClip:
                                route.text == "Noah" && "text",
                              WebkitTextFillColor:
                                route.text == "Noah" && "transparent",
                              flex: route.text == "Noah" && "0 1 auto",
                              fontWeight: route.text == "Noah" && "600",
                            }}
                            primary={route.text}
                          />
                          {route.text == "Noah" && (
                            <img
                              src={Sparkle}
                              alt={"sparkle"}
                              style={{
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          )}
                        </ListItem>
                      ))}
                      {roles === "mentor" && (
                        <ListItem
                          button
                          key="Tracker"
                          onClick={() => navigateTo("/tracker")}
                          style={{
                            color: location.pathname.includes("/tracker")
                              ? theme.palette.highlightColor
                              : theme.palette.caption1,
                            paddingLeft: 18,
                            marginBottom: 30,
                          }}
                        >
                          <img
                            src={
                              location.pathname.includes("/tracker")
                                ? `${ASSETS_URL}tracker_active.svg`
                                : isDarkMode
                                ? `${ASSETS_URL}tracker_passive_dark.svg`
                                : `${ASSETS_URL}tracker_passive.svg`
                            }
                            alt={"tracker-icon"}
                            className={classes.sidebarImg}
                          />
                          <ListItemText
                            style={{ fontSize: "14px", marginLeft: "15px" }}
                            primary={"Tracker"}
                          />
                        </ListItem>
                      )}
                    </List>
                  </div>
                  <div>
                    <div>
                      <div className={classes.profileContainer}>
                        <div className={classes.userContainer}>
                          <div className={classes.userWrapper}>
                            <img
                              src={
                                userData.profileImage
                                  ? userData.profileImage
                                  : role === "admin"
                                  ? admin
                                  : role === "student"
                                  ? defaultUser
                                  : role === "mentor"
                                  ? mentor
                                  : defaultUser
                              }
                              alt="user"
                              className={classes.userPic}
                            />
                            <div className={classes.userName}>
                              {" "}
                              {userData.userName?.trim(" ").split(" ")[0]}
                            </div>
                          </div>
                          <div className={classes.userRole}>
                            {role === "admin"
                              ? "Admin"
                              : role === "student"
                              ? "Student"
                              : role === "mentor"
                              ? "Mentor"
                              : "Guest user"}
                          </div>
                          <div
                            className={classes.logoutContainer}
                            onClick={logout}
                          >
                            <span className={classes.logoutText}>Logout</span>
                            <IconButton className={classes.logoutIcon}>
                              <PowerSettingsNewIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
          {matches === true && location.pathname.includes("files") ? (
            <Typography
              variant="subtitle1"
              style={{ color: "#959595", textAlign: "center" }}
            >
              All of your code files are saved here
            </Typography>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default HeaderBar;
