import React, { useState, useRef, useEffect } from "react";
import { useStyles } from "../style";
import { Button, Grid, Checkbox } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";
import "../../../Courses/Maths/playground.css";

const AdminTextQuestions = ({ options, setOptions, answerType }) => {
  const classes = useStyles();
  const [enableMathMode, setEnableMathMode] = useState(false);
  const [mathText, setMathText] = useState("");
  const textareaRef = useRef(null);
  const mathFieldRefs = useRef([]);

  const handleOptionChange = (index, event) => {
    const newOptions = options.map((option, i) => {
      if (i === index) {
        return { ...option, text: event.target.value };
      }
      return option;
    });
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([
      ...options,
      {
        text: "",
        correct: 1,
        sequence: options.length + 1,
        disabled: false,
        isMathInput: false,
        hasImage: 0,
        imageUploaded: false,
      },
    ]);
  };

  const handleRemoveOption = (index) => {
    let newOptions = options.filter((option, i) => i !== index);
    newOptions = newOptions.map((row, idx) => {
      return {
        ...row,
        sequence: idx + 1,
        answerSequence: idx + 1,
      };
    });
    setOptions(newOptions);
  };

  // const handleMathInputToggle = (index) => {
  //   const newOptions = [...options];
  //   newOptions[index].isMathInput = !newOptions[index].isMathInput;
  //   setOptions(newOptions);
  // };

  // const handleMathInput = (index, value) => {
  //   const newOptions = [...options];
  //   newOptions[index].text = value;
  //   setOptions(newOptions);
  // };

  const renderOptionInput = (option, index) => {
    // if (enableMathMode) {
    //   return (
    //     <div style={{ cursor: "text", width: "100%" }}>
    //       <math-field
    //         ref={(el) => {
    //           if (el) {
    //             mathFieldRefs.current[index] = el;
    //             el.value = option.text;
    //           }
    //         }}
    //         onInput={(evt) => handleMathInput(index, evt.target.value)}
    //         virtual-keyboard-mode="manual"
    //         style={{
    //           width: "100%",
    //           minHeight: "40px",
    //           padding: "8px",
    //           border: "1px solid #ccc",
    //           borderRadius: "4px",
    //         }}
    //       >
    //         {mathText}
    //       </math-field>
    //     </div>
    //   );
    // }

    return (
      <textarea
        rows={1}
        value={option.text}
        onChange={(event) => handleOptionChange(index, event)}
        placeholder={`Varaition ${index + 1}`}
        className={classes.mcqOption}
        disabled={option.disabled}
      />
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          // maxHeight: "30vh",
          overflow: "auto",
        }}
      >
        {options.map((option, index) => (
          <div
            key={index}
            className={`${classes.flex_row} ${classes.alignItemsCenter} mt-2`}
          >
            <div className={classes.mcqOptionContainer}>
              {renderOptionInput(option, index)}
              {/* <div className="ml-2 flex items-center">
                <Checkbox
                  checked={option.isMathInput}
                  onChange={(e) => setEnableMathMode(e.target.checked)}
                  color="primary"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "50%",
                      border: "2px solid #1976d2",
                    },
                  }}
                />
              </div> */}
            </div>
            {index > 0 ? (
              <div>
                <DeleteOutlineOutlinedIcon
                  onClick={() => handleRemoveOption(index)}
                  style={{
                    color: "#E76B69",
                    cursor: "pointer",
                    margin: "auto 0 auto 10px",
                  }}
                />
              </div>
            ) : (
              <div style={{ width: "8%" }}></div>
            )}
          </div>
        ))}
      </div>
      <Grid className={`${classes.flex_row} ${classes.justifyRight} mt-2 mr-4`}>
        <Button
          className={`${classes.addOptionBtn} ${classes.font500} ${classes.font14}`}
          onClick={addOption}
        >
          <strong>Add Variartions +</strong>
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default AdminTextQuestions;
