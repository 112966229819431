import React, { useState } from "react";
import { useStyles } from "../style";
import { Button, Grid } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";
import ImageUploader from "./ImageUploader";
import { IconButton, Paper } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import UploadImage from "../../../../assets/images/upload-photo.svg";

const AdminMcqQuestions = ({
  options,
  setOptions,
  answerType,
  setQuestionData,
  questionData,
  setImagesArray,
  imagesArray,
}) => {
  const classes = useStyles();
  const [imageCropperModal, setImageCropperModal] = useState(false);
  const [currentOptionIndex, setCurrentOptionIndex] = useState(null);

  const handleOptionChange = (index, event) => {
    const newOptions = options.map((option, i) => {
      if (i === index) {
        return { ...option, text: event.target.value };
      }
      return option;
    });
    setOptions(newOptions);
  };

  const handleCorrectChange = (index) => {
    if (answerType === "multi-select") {
      const newOptions = options.map((option, i) => {
        const correct = i === index ? (option.correct ? 0 : 1) : option.correct;
        return { ...option, correct };
      });
      setOptions(newOptions);
    } else {
      let errorFlag = false;
      options.forEach((option) => {
        if (option.disabled && option.correct) {
          toast.error(
            "Delete disabled correct answer before assigning new correct answer!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          errorFlag = true;
        }
      });
      if (errorFlag) {
        return;
      }
      const newOptions = options.map((option, i) => {
        const correct = i === index ? 1 : 0;
        return { ...option, correct };
      });
      setOptions(newOptions);
    }
  };

  const addOption = () => {
    setOptions([
      ...options,
      {
        text: "",
        correct: 0,
        sequence: options.length + 1,
        disabled: false,
        hasImage: 0,
        imageUploaded: false,
      },
    ]);
  };

  const handleRemoveOption = (index) => {
    let newOptions = options.filter((option, i) => i !== index);
    newOptions = newOptions.map((row, idx) => {
      return {
        ...row,
        sequence: idx + 1,
        answerSequence: idx + 1,
      };
    });
    setOptions(newOptions);

    const newImagesArray = imagesArray.filter((_, i) => i !== index);
    setImagesArray(newImagesArray);
  };

  const handleAddImage = (index) => {
    setCurrentOptionIndex(index);
    setImageCropperModal(true);
  };
  const handleImageSave = ({ displayUrl, file }) => {
    const newOptions = [...options];
    newOptions[currentOptionIndex].image = displayUrl;
    newOptions[currentOptionIndex].hasImage = 1;
    newOptions[currentOptionIndex].imageUploaded = true;
    console.log(newOptions, "new options");
    setOptions(newOptions);

    // const newQuestionData = { ...questionData };
    // newQuestionData.images = [...questionData.images];
    // newQuestionData.images[currentOptionIndex] = file;
    // setQuestionData(newQuestionData);
    const newImagesArray = [...imagesArray];
    newImagesArray[currentOptionIndex] = file;
    setImagesArray(newImagesArray);

    setImageCropperModal(false);
  };

  const handleRemoveImage = (index) => {
    // Remove image from option
    const newOptions = [...options];
    newOptions[index].image = null;
    newOptions[index].hasImage = 0;
    newOptions[index].imageUploaded = false;
    setOptions(newOptions);

    // Remove image from images array while maintaining sequence

    const newImagesArray = imagesArray.filter((_, i) => i !== index);
    setImagesArray(newImagesArray);

    // const newImagesArray = [...imagesArray];
    // newImagesArray[index] = null;
    // setImagesArray(newImagesArray);
  };

  const closeCropperModal = () => {
    setImageCropperModal(false);
  };

  const renderOptionInput = (option, index) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
        <textarea
          rows={1}
          value={option.text}
          onChange={(event) => handleOptionChange(index, event)}
          placeholder={`Enter answer ${index + 1}`}
          className={classes.mcqOption}
          disabled={option.disabled}
        />
        {option.hasImage ? (
          <Paper
            elevation={3}
            sx={{
              position: "relative",
              overflow: "hidden",
              borderRadius: 2,
              background: "#F0F0F0",
            }}
          >
            <img
              src={
                option.image
                  ? option.image
                  : `${process.env.REACT_APP_ADMIN_PREVIEW_URL}answerImage/${option.answerId}`
              }
              alt={`Option ${index + 1}`}
              style={{
                width: "100%",
                height: "128px",
                display: "block",
                objectFit: "contain",
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                bgcolor: "background.paper",
              }}
              onClick={() => {
                handleRemoveImage(index);
              }}
              // onClick={handleDelete}
              size="small"
              disabled={option.disabled}
            >
              <DeleteIcon />
            </IconButton>
          </Paper>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      {imageCropperModal && (
        <ImageUploader
          imageCropperModal={imageCropperModal}
          closeCropperModal={closeCropperModal}
          finalImage={options[currentOptionIndex]?.image}
          handleImageSave={handleImageSave}
        />
      )}
      <div
        style={{
          // maxHeight: "30vh",
          overflow: "auto",
        }}
      >
        {options.map((option, index) => (
          <div
            key={index}
            className={`${classes.flex_row} ${classes.alignItemsCenter} mt-2`}
          >
            <div className={classes.mcqOptionContainer}>
              {renderOptionInput(option, index)}
              <div
                className={classes.labelInput}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1",
                  justifyContent: "end",
                }}
              >
                <div
                  className="ml-2 flex items-center"
                  style={{
                    marginRight: "10px",
                  }}
                >
                  {/* <Button
                    className={`${classes.addOptionBtn} ${classes.font500} ${classes.font14}`}
                    onClick={() => handleAddImage(index)}
                  >
                  </Button> */}
                  <IconButton
                    onClick={() => handleAddImage(index)}
                    style={{
                      color: "#E76B69",
                      cursor: option.disabled
                        ? "not-allowed !important"
                        : "pointer",
                    }}
                    disabled={option.disabled}
                  >
                    <img
                      src={UploadImage}
                      alt="upload-img"
                      style={{ height: "24px", width: "24px" }}
                    />
                  </IconButton>
                </div>
                <input
                  type={answerType === "multi-select" ? "checkbox" : "radio"}
                  checked={option.correct}
                  onChange={() => handleCorrectChange(index)}
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                  disabled={option.disabled}
                  className={
                    answerType === "multi-select"
                      ? classes.customCheckbox
                      : classes.customRadioButton
                  }
                />
              </div>
            </div>
            <div>
              <DeleteOutlineOutlinedIcon
                onClick={() => handleRemoveOption(index)}
                style={{
                  color: "#E76B69",
                  cursor: "pointer",
                  margin: "auto 0 auto 10px",
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <Grid className={`${classes.flex_row} ${classes.justifyRight} mt-2 mr-4`}>
        <Button
          className={`${classes.addOptionBtn} ${classes.font500} ${classes.font14}`}
          onClick={addOption}
        >
          <strong>Add Option +</strong>
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default AdminMcqQuestions;
