import Cookies from "js-cookie";
import { decrypt } from "../../../../Core/utils/others";
import { getRole } from "../../../../Core/utils/auth";
import jwt_decode from "jwt-decode";

export const navigateToQuiz = (userData) => {
  const role = getRole();
  const sandbox_user_data = decrypt(localStorage.getItem("sandbox_user_data"));
  const accesToken = decrypt(localStorage.getItem("sandbox_user"));
  const userInfo = jwt_decode(accesToken.ACCESS_TOKEN).userInfo;
  const userId = userInfo.userId;
  const allowedUsers = process.env?.REACT_APP_ALLOWED_USERS?.split(",") || [];

  if(role != "guest"){
    Cookies.set("userName", sandbox_user_data?.studentName, {
      path: "/",
      domain: ".codeyoung.com",
    });
    Cookies.set("grade", sandbox_user_data?.grade, {
      path: "/",
      domain: ".codeyoung.com",
    });
  }
  Cookies.set("userId", userId, {
    path: "/",
    domain: ".codeyoung.com",
  });
  Cookies.set("userRole", role, {
    path: "/",
    domain: ".codeyoung.com",
  });

  const url = allowedUsers.includes(accesToken.email)
    ? process.env?.REACT_APP_AI_QUIZ_URL_DEV
    : process.env?.REACT_APP_AI_QUIZ_URL;

  window.open(`${url}`);
};
