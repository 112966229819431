import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import uploadIcon from "../../../assets/images/upload.png";
import infoIcon from "../../../assets/images/info.png";
import { Spinner } from "react-bootstrap";
import ErrorPopUp from "./ErrorPopUp";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { API } from "../../../Services/api-service";
import Playground from "./playground";
import { getRole } from "../../../Core/utils/auth";
import axios from "axios";
import IFrame from "./IFrame";
import Button from "@material-ui/core/Button";
import Loader from "../../../Core/Loader/Loader";

import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import { decrypt } from "../../../Core/utils/others";
import { CompareHtml } from "./MathsTrackingHelper";

const useStyles = makeStyles((theme) => ({
  parentBox: {
    height: "850px",
    background: "#F9FBFB",
  },
  parent: {
    height: "100%",
  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  uploadFile: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "max-content",
  },
  uploadFileBtn: {
    padding: "8px 30px",
    display: "flex",
    color: "#FF712D",
    height: "40px",
    background: "white",
    border: "1px solid #FF712D",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white",
      color: "#FF712D",
    },
  },
  info: {
    color: "#959595",
    fontSize: "14px",
  },
  file: {
    display: "none",
    marginTop: "30%",
  },
  uploadIcn: {
    margin: "0 0 0 12px",
  },
  infoIcon: {
    margin: "-2px 6px 0 0",
  },
  header: {
    height: "60px",
    background: "#F9FBFB",
    display: "flex",
    alignItems: "center",
  },
  workSheetArea: {
    background: "#FFFFFF",
  },
  headerBtn: {
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    width: "110px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FF712D",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    marginRight: "35px",
  },
  headerIcon: {
    width: "18px",
    height: "18px",
    margin: "-1px 0 0 6px",
  },

  btnWrapper: {
    display: "flex",
    justifyContent: "flex-start",
  },

  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: "1",
  },
}));

const WorkSheet = forwardRef((props, ref) => {
  const role = getRole();
  const classes = useStyles();
  const [imported, setImported] = useState(0);
  const [status, setStatus] = useState("");
  const [answers, setAnswers] = useState("");
  const [style, setStyle] = useState("");
  const [forceClearAns, setForceClearAns] = useState(false);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addPageLoader, setAddPageLoader] = useState(false);
  const theme = useTheme();
  const childRef = useRef(null);
  const { catalogId, exerciseId } = useParams();
  const [html, setHtml] = useState("");
  const accesToken = decrypt(localStorage.getItem("sandbox_user"));
  const userInfo = jwt_decode(accesToken.ACCESS_TOKEN).userInfo;
  const userID = userInfo.userId;

  useEffect(() => {
    if (props.totalPages > 0) {
      if (
        role !== "admin" ||
        (props.exercise?.properties &&
          props.exercise?.properties?.hasOwnProperty("imported") &&
          props.exercise?.properties?.imported)
      ) {
        props.setWorkSheet(props.exercise?.src);
        setStatus("iframe");
        getHtml(props.exercise?.src);
        setStyle(props.exercise?.properties?.style);
        setImported(props.exercise?.properties?.imported);
      } else {
        if (props.exercise?.properties) {
          props.setWorkSheet(props.exercise?.properties?.backgroundUrl);
          setAnswers(props.exercise?.properties?.answers);
          setStyle(props.exercise?.properties?.style);
          if (
            props.exercise?.properties?.hasOwnProperty("imported") &&
            props.exercise?.properties?.imported
          ) {
            setImported(props.exercise?.properties?.imported);
          }
          setStatus("playground");
        }
      }
    }
  }, [props.exercise?.pageNo]);
  useEffect(() => {
    if (props.totalPages > 0) {
      if (role !== "admin") {
        props.setWorkSheet(props.exercise?.src);
        setStatus("iframe");
        if (props.loadOriginalState) {
          loadOriginalState(props.exercise?.originalState);
          props.setLoadOriginalState(false);
        }
        setStyle(props.exercise?.properties?.style);
        setImported(props.exercise?.properties?.imported);
      }
    }
  }, [props.loadOriginalState]);

  useEffect(() => {
    if (props.totalPages < 1) {
      props.setWorkSheet("");
    }
  }, [props.totalPages]);

  const addPage = () => {
    if (props.totalPages === 0) {
      setAddPageLoader(true);
      API.post(`/exercises/${exerciseId}/page`)
        .then((res) => {
          props.setPageData(res?.data.pageId);
          props.fetchExercise();
          setAddPageLoader(false);
        })
        .catch((err) => {
          const event = {
            message: "Add Page error",
            level: Sentry.Severity.Info,
            extra: {
              exerciseId: exerciseId,
              userID: userID,
            },
          };
          Sentry.captureEvent(event);
          setAddPageLoader(false);
          console.log("error while fetching data", err);
        });
    }
  };
  const isImageCorrupt = (data) => {
    let frame = `${data}`;
    let tempElement = document.createElement("div");
    tempElement.innerHTML = frame;
    let imgElements = tempElement.querySelectorAll("img");
    let htmlResponseArr = [];
    imgElements.forEach((img) => {
      let src = img.getAttribute("src");
      if (src) {
        htmlResponseArr.push(src);
      }
    });
    if (props.exercise?.properties?.backgroundUrl.length > 0) {
      for (
        let i = 0;
        i < props.exercise?.properties?.backgroundUrl.length;
        i++
      ) {
        if (
          props.exercise?.properties?.backgroundUrl[i]?.slice(
            props.exercise?.properties?.backgroundUrl[i]?.lastIndexOf("/")
          ) !==
            htmlResponseArr[i]?.slice(htmlResponseArr[i]?.lastIndexOf("/")) ||
          htmlResponseArr[i]?.slice(htmlResponseArr[i]?.lastIndexOf("/")) ===
            undefined
        ) {
          return true;
        }
      }
    }
  };
  const updateHtmlImages = (data) => {
    if (isImageCorrupt(data) === true) {
      const html = `${data}`;
      var tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      var imageDiv = tempDiv.querySelector("#canvas");

      var existingImages = imageDiv.querySelectorAll("img");
      let htmlResponseArr = [];
      for (var i = 0; i < existingImages.length; i++) {
        let src = existingImages[i].getAttribute("src");
        if (src) {
          htmlResponseArr.push(src);
        }
        existingImages[i].remove();
      }

      for (
        var i = 0;
        i < props.exercise?.properties?.backgroundUrl.length;
        i++
      ) {
        var img = document.createElement("img");
        img.src = props.exercise?.properties?.backgroundUrl[i];
        img.alt = "test";
        img.style.width = "100%";
        img.style.height =
          props.exercise?.properties?.hasOwnProperty("imported") &&
          props.exercise?.properties?.imported &&
          `${Math.floor(
            100 / props.exercise?.properties?.backgroundUrl.length
          )}%`;
        imageDiv.appendChild(img);
      }

      var resultHTML = tempDiv.innerHTML;
      setHtml(resultHTML);
      const event = {
        message: "Html content updated for corrupt images",
        level: Sentry.Severity.Info,
        extra: {
          exerciseId: exerciseId,
          userID: userID,
          existingImagesUrl: htmlResponseArr,
          API_images: props.exercise?.properties?.backgroundUrl,
        },
      };
      Sentry.captureEvent(event);
    } else {
      setHtml(data);
    }
  };

  const fileUploadHandler = (e) => {
    setLoader(true);
    if (!props.pageId && !props.pageId) {
      setLoader(false);
      return toast.error("Please Add a page before uploading file", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    const formData = new FormData();
    formData.append("exerciseId", exerciseId);
    formData.append("file", e.target.files[0]);
    API.post(
      `/exercises/${exerciseId}/page/${
        props.pageId ? props.pageId : props.pageId
      }/src`,
      formData
    )
      .then((res) => {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.setWorkSheet(res.data.url);
        setLoader(false);
        props.fetchExercise();
      })
      .catch((e) => {
        const event = {
          message: "Background upload error",
          level: Sentry.Severity.Info,
          extra: {
            exerciseId: exerciseId,
            userID: userID,
          },
        };
        Sentry.captureEvent(event);
        toast.error("Error While Uploading Background", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
      });
  };

  const closeError = () => {
    setError(false);
  };

  const logSentry = (msg, url, additionalInfo) => {
    const event = {
      message: msg,
      level: Sentry.Severity.Info,
      extra: {
        exerciseId: exerciseId,
        userID: userID,
        url: url,
        message: additionalInfo,
      },
    };
    Sentry.captureEvent(event);
  };

  const loadOriginalState = (url) => {
    axios
      .get(url)
      .then((response) => {
        setHtml(response.data);
        props.setParentHtml(response.data);
      })
      .catch((e) => {
        // logSentry(e, "Maths Exercise Loading Error", url, "s3 api failure");
        console.log("err", e);
      });
  };

  const getHtml = (url) => {
    let strippedUrl = url?.split("?")[0];
    axios
      .get(url)
      .then((response) => {
        if (response.data !== "undefined") {
          // setHtml(response.data);
          updateHtmlImages(response.data);
          props.setParentHtml(response.data);
          if (props.exercise?.originalState == props.exercise?.src) {
            CompareHtml(
              "loading",
              response.data,
              props.exercise?.properties?.backgroundUrl,
              exerciseId,
              userID,
              props.setMissingImage
            );
          }
          setForceClearAns(false);
        } else {
          logSentry(
            "Maths Exercise File Corruption Error",
            url,
            "file is corrupted"
          );
          if (strippedUrl !== props.exercise?.originalState) {
            axios
              .get(props.exercise?.originalState)
              .then((response) => {
                //   setHtml(response.data);
                updateHtmlImages(response.data);
                props.setParentHtml(response.data);
                if (props.exercise?.originalState == props.exercise?.src) {
                  CompareHtml(
                    "loading",
                    response.data,
                    props.exercise?.properties?.backgroundUrl,
                    exerciseId,
                    userID,
                    props.setMissingImage
                  );
                }
                // setForceClearAns(true);
              })
              .catch((e) => {
                logSentry(
                  "Maths Exercise Loading Error",
                  url,
                  "s3 api success but content is undefined"
                );
              });
          }
        }
      })
      .catch((e) => {
        logSentry("Maths Exercise S3 File not found", url, "file is corrupted");
        console.log('"file is corrupted": ', "file is corrupted");
        if (strippedUrl != props.exercise?.originalState) {
          axios
            .get(props.exercise?.originalState)
            .then((response) => {
              //    setHtml(response.data);
              updateHtmlImages(response.data);
              props.setParentHtml(response.data);
              if (props.exercise?.originalState == props.exercise?.src) {
                CompareHtml(
                  "loading",
                  response.data,
                  props.exercise?.properties?.backgroundUrl,
                  exerciseId,
                  userID,
                  props.setMissingImage
                );
              }
              // setForceClearAns(true);
            })
            .catch((e) => {
              logSentry(
                e,
                "Maths Exercise Loading Error",
                url,
                "s3 api failure"
              );
            });
        }
      });
  };

  const handleStatus = (status, answers) => {
    setAnswers(answers);
  };

  useImperativeHandle(ref, () => ({
    save() {
      childRef.current.handleSave();
    },

    format() {
      childRef.current.handleFormat();
    },

    delete() {
      childRef.current.handleDelete();
    },
    copy() {
      childRef.current.handleCopy();
    },
    drag() {
      childRef.current.handleDrag();
    },

    type() {
      childRef.current.handleType();
    },

    handleReplace() {
      props.setWorkSheet("");
      setAnswers("");
    },

    createBox() {
      childRef.current.createBox();
    },
  }));

  const handleCancel = (value) => {
    value ? setImported(true) : setImported(false);
    props.displayCancel(value);
  };

  return (
    <div>
      {role === "admin" ? (
        <div className={!props.workSheet ? classes.parentBox : classes.parent}>
          {error && <ErrorPopUp open={error} closeErrorPopup={closeError} />}
          {!props.workSheet && props.totalPages < 1 ? (
            <div className={classes.innerBox}>
              <label className={classes.uploadFile}>
                <Button
                  className={classes.uploadFileBtn}
                  disabled={addPageLoader}
                  onClick={addPage}
                  style={{ minWidth: "140px" }}
                >
                  {addPageLoader ? (
                    <Spinner animation="border" variant="orange" />
                  ) : (
                    "Add Page"
                  )}
                </Button>
              </label>
            </div>
          ) : !props.workSheet ? (
            <div className={classes.innerBox}>
              <input
                type="file"
                id="file"
                accept=".jpg, .png, .jpeg*, .gif, .pdf"
                className={classes.file}
                onChange={(e) => fileUploadHandler(e)}
              />
              <label htmlFor="file" className={classes.uploadFile}>
                <div className={classes.uploadFileBtn}>
                  <span>UPLOAD WORKSHEET</span>
                  <img
                    src={uploadIcon}
                    alt="upload"
                    className={classes.uploadIcn}
                  />
                </div>
              </label>

              <div className={classes.info}>
                <img src={infoIcon} alt="info" className={classes.infoIcon} />
                <span>
                  Please ensure to upload only PDF, docx, jpeg, png and jpg
                  files.
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.workSheetArea}>
                {props.exercise?.properties &&
                props.exercise?.properties?.hasOwnProperty("imported") &&
                props.exercise?.properties?.imported ? (
                  html &&
                  (html != undefined || html != null || html != "") && (
                    <IFrame
                      html={html}
                      clearAnswers={true}
                      answers={false}
                      ref={childRef}
                      styles={style}
                      exerciseId={exerciseId}
                      handleStatus={handleStatus}
                      properties={props.properties}
                      role={role}
                      handleTotalScore={props.handleTotalScore}
                      tab={props.tab}
                      submission={props.submission}
                      handleBoxSelection={props.handleBoxSelection}
                      fetchExercise={props.fetchExercise}
                      pageId={props.pageId}
                      exercise={props.exercise}
                      currentPage={props.currentPage}
                      setWorkSheetData={props.setWorkSheetData}
                      keyboard={props.keyboard}
                      handleChangeAnswers={props.handleChangeAnswers}
                      changedAnswers={props.changedAnswers}
                    />
                  )
                ) : (
                  <div>
                    {loader ? (
                      <Loader />
                    ) : (
                      <Playground
                        ref={childRef}
                        workSheet={props.workSheet}
                        exerciseId={exerciseId}
                        handleStatus={handleStatus}
                        answers={answers}
                        styles={style}
                        displayCancel={handleCancel}
                        handleBoxSelection={props.handleBoxSelection}
                        showTextEditor={props.showTextEditor}
                        fetchExercise={props.fetchExercise}
                        setShowAddPageBtn={props.setShowAddPageBtn}
                        pageId={props.pageId}
                        currentPage={props.currentPage}
                        exercise={props.exercise}
                        setWorkSheetData={props.setWorkSheetData}
                        keyboard={props.keyboard}
                        ansVariations={props.ansVariations}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Box sx={{ background: "#F9FBFB" }}>
          {html && (html != undefined || html != null || html != "") && (
            <IFrame
              html={html}
              clearAnswers={
                forceClearAns ||
                Object.keys(
                  props.exercise?.answers ? props.exercise?.answers : {}
                ).length === 0
              }
              answers={false}
              ref={childRef}
              styles={style}
              exerciseId={exerciseId}
              handleStatus={handleStatus}
              exercise={props.exercise}
              properties={props.properties}
              role={role}
              handleTotalScore={props.handleTotalScore}
              reattempt={props.reattempt}
              handleReattempt={props.handleReattempt}
              tab={props.tab}
              submission={props.submission}
              pageId={props.pageId}
              currentPage={props.currentPage}
              setWorkSheetData={props.setWorkSheetData}
              keyboard={props.keyboard}
              handleChangeAnswers={props.handleChangeAnswers}
              changedAnswers={props.changedAnswers}
            />
          )}
        </Box>
      )}
    </div>
  );
});

export default WorkSheet;
