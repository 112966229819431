import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useState } from "react";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { getRole } from "../../Core/utils/auth";

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  breadcrumbWrapper: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "space-between",
  },
  breadcrumbs: {
    fontSize: "14px !important",
    marginTop: "20px",
  },
  links: {
    color: "#959595",
    textDecoration: "none",
    fontSize: "14px !important",
    lineHeight: "17px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: "#959595",
    },
  },

  navigateBtn: {
    fontSize: "14px",
    color: "#F2B202",
    fontWeight: "600",
    textTransform: "capitalize",
  },
  navigateBtn2: {
    fontSize: "14px",
    color: "#569090",
    fontWeight: "600",
    textTransform: "capitalize",
  },
  showIcon: {
    "& > *:first-child": {
      fontSize: "14px",
    },
  },

  downloadIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "63px",
    fontSize: "14px",
    color: "#FF712D",
    lineHeight: "16.4px",
    textTransform: "capitalize",
    fontWeight: "600",
  },
  endIcon: {
    "& > *:first-child": {
      fontSize: "25px",
    },
  },
}));

export default function BreadcrumbsSimple({
  catalogId,
  topicId,
  catalogueName,
  exerciseId,
  exerciseTitle,
  collectionId,
  fromAssignment,
  maths,
  goals,
  showBreadcrumb = true,
  section,
}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const role = getRole();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const [assignedToMeStatus, setAssignedToMeStatus] = useState(
    location.pathname.includes("assignedToMe") || fromAssignment
  );
  const prevCollectionRoute = () => {
    history.goBack();
  };

  const handleHome = () => {
    history.push("/exercises");
  };

  return (
    <>
      <>
        {(catalogId || collectionId || assignedToMeStatus || topicId) &&
          showBreadcrumb && (
            <div className={classes.breadcrumbContainer}>
              <Button
                variant="text"
                startIcon={<ArrowBackIcon />}
                className={`
                    
                    ${goals ? classes.navigateBtn2 : classes.navigateBtn}`}
                onClick={prevCollectionRoute}
                classes={{ iconSizeMedium: classes.showIcon }}
                style={{ marginRight: "20px" }}
              >
                {section === "goalsCardSection" ? "Back" : "Go Back"}
              </Button>
              {!(goals || maths) && (
                <Button
                  variant="text"
                  startIcon={<HomeOutlinedIcon />}
                  className={classes.navigateBtn}
                  onClick={handleHome}
                  classes={{ iconSizeMedium: classes.showIcon }}
                >
                  Go To Home
                </Button>
              )}
            </div>
          )}
      </>
    </>
  );
}
