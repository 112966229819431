import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./style";
import Timer from "../Timer";
import { getRole } from "../../../Core/utils/auth";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import Instructions from "./Instructions";
import Video from "./Video";
import InstructionType from "./InstructionType";
import Questions from "./Questions";
import NextIcon from "@mui/icons-material/ChevronRight";
import BackIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import SectionSuccessPage from "./SectionSuccessPage";

const componentStyles = makeStyles((theme) => ({
  layout: {
    padding: "24px 44px",
  },
  cancelBtn: {
    padding: "8px 30px",
    marginRight: "3%",
    color: "#939393",
    width: "110px",
    height: "41px",
    background: "#FFFFFF",
    borderRadius: "6px",
    border: "1px solid #939393",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 44px 20px 44px",
  },

  closeIconWrapper: {
    display: "flex",
    justifyContent: "end",
    marginRight: "20px",
  },

  closeIcon: {
    "& > *:first-child": {
      fontSize: "25px",
    },
  },
}));

const Preview = ({
  open,
  preview,
  closePreview,
  role,
  isExerciseCompleted,
  showTimer,
  timerDuration,
  startTime,
  endTime,
  openEnded,
  handleSave,
  questionData,
  viewResults,
  sectionNo,
  handleNext,
  handleAnswers,
  setHasAnswered,
  questionSetIndex,
  handlePreviousQuestionSet,
  nextSection,
  setIconDisabled,
  nextLoader,
  handleOpenEnded,
  iconDisabled,
  prevSection,
  handlePrevious,
  sections,
  sectionLevelData,
  handleNextExercise,
  exercise,
  handleReattempt,
  handleViewResults,
  completedTime,
}) => {
  const componentClasses = componentStyles();
  const classes = useStyles();

  const tooltipProps = {
    title: iconDisabled
      ? "Please answer question and click next to proceed"
      : "",
    arrow: true,
  };

  return (
    <Dialog fullScreen open={open} className={componentClasses.layout}>
      <DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={closePreview}
          aria-label="close"
          className={componentClasses.closeIconWrapper}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container xs={12}>
          <Grid item xs={12}>
            {openEnded && (
              <Grid className={classes.saveWrapper}>
                <div className={classes.saveIcon} onClick={handleSave}>
                  Save
                </div>
                <IconButton
                  onClick={handleSave}
                  style={{
                    color: "#FF712D",
                    padding: "0px",
                  }}
                >
                  <SaveIcon style={{ fontSize: "20px" }} />
                </IconButton>
              </Grid>
            )}
            {viewResults && questionData?.questionType !== "Question Set" && (
              <Grid className={classes.insightsScores}>
                Score :{" "}
                <span
                  className={classes.actualScore}
                >{`${questionData?.scored} / ${questionData?.score}`}</span>
              </Grid>
            )}
            {isExerciseCompleted && !viewResults ? (
              <SectionSuccessPage
                sectionLevelData={sectionLevelData}
                handleNextExercise={handleNextExercise}
                exercise={exercise}
                handleReattempt={handleReattempt}
                handleViewResults={handleViewResults}
                completedTime={completedTime}
              />
            ) : (
              <Box
                className={`${classes.sectionsWrapper} ${
                  questionData?.questionType === "Question Set" &&
                  classes.fullHeight
                }`}
              >
                <Box
                  className={`${
                    questionData?.questionType === "Question Set"
                      ? classes.sectionsInstructionType
                      : classes.sectionsContainer
                  } ${
                    questionData?.questionType === "Question Set" &&
                    classes.fullHeight
                  }`}
                >
                  <Grid
                    className={
                      questionData?.questionType === "Question Set" &&
                      classes.fullHeight
                    }
                  >
                    {questionData && (
                      <div key={sectionNo} className={classes.fullHeight}>
                        {questionData?.questionType === "Question Set" ? (
                          <Box className={classes.fullHeight}>
                            <InstructionType
                              handleOpenEnded={handleOpenEnded}
                              preview={preview}
                              questionData={questionData}
                              handleNextSection={handleNext}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              questionSetIndex={questionSetIndex}
                              handlePreviousQuestionSet={
                                handlePreviousQuestionSet
                              }
                              nextSection={nextSection}
                              viewResults={viewResults}
                              setIconDisabled={setIconDisabled}
                              nextLoader={nextLoader}
                              isExerciseCompleted={isExerciseCompleted}
                            />
                          </Box>
                        ) : questionData?.questionType ===
                          "Instruction - Text" ? (
                          <Box>
                            <Instructions
                              questionData={questionData}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              setIconDisabled={setIconDisabled}
                              handleOpenEnded={handleOpenEnded}
                              preview={preview}
                            />
                          </Box>
                        ) : questionData?.questionType ===
                          "Instruction - Video" ? (
                          <Box>
                            <Video
                              handleOpenEnded={handleOpenEnded}
                              preview={preview}
                              questionData={questionData}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              setIconDisabled={setIconDisabled}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <Questions
                              data={questionData}
                              handleNextSection={handleNext}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              questionSetIndex={questionSetIndex}
                              handlePreviousQuestionSet={
                                handlePreviousQuestionSet
                              }
                              nextSection={nextSection}
                              viewResults={viewResults}
                              setIconDisabled={setIconDisabled}
                              nextLoader={nextLoader}
                              isExerciseCompleted={isExerciseCompleted}
                              handleOpenEnded={handleOpenEnded}
                            />
                          </Box>
                        )}
                      </div>
                    )}
                  </Grid>
                  {questionData?.questionType !== "Question Set" && (
                    <Box
                      className={`${classes.actionsContainer} ${classes.justifyCenter}`}
                    >
                      {nextSection ? (
                        <Button
                          onClick={() => handleNext("next")}
                          className={classes.actionNextBtn}
                          endIcon={<NextIcon className={classes.nextIcon} />}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleNext("finish")}
                          className={classes.actionNextBtn}
                        >
                          Finish
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
                {(!isExerciseCompleted ||
                  (isExerciseCompleted && viewResults)) && (
                  <Box className={classes.sectionCountContainer}>
                    <Box
                      className={`${classes.qNoBox} ${classes.sectionCountWrapper} ${classes.width100}`}
                    >
                      {prevSection && (
                        <Box>
                          <IconButton
                            onClick={handlePrevious}
                            style={{
                              color: "#2F4F4F",
                              padding: "0px",
                              marginRight: "20px",
                            }}
                          >
                            <BackIcon style={{ fontSize: "20px" }} />
                          </IconButton>
                        </Box>
                      )}
                      <Box
                        className={` ${classes.flex_row} ${classes.alignItemsCenter} ${classes.sectionCountText}`}
                      >
                        Section {sectionNo} of {sections?.length}
                      </Box>
                      {(questionData?.questionType !== "Question Set" ||
                        (role === "admin" && preview === false) ||
                        viewResults) &&
                        nextSection && (
                          <Tooltip {...tooltipProps}>
                            <Box>
                              <IconButton
                                onClick={() =>
                                  handleNext(
                                    (role === "admin" && preview === false) ||
                                      viewResults
                                      ? "nextSection"
                                      : "pagination"
                                  )
                                }
                                style={{
                                  color:
                                    iconDisabled &&
                                    (role !== "admin" || preview)
                                      ? "#b6cfcf"
                                      : "#2F4F4F",
                                  padding: "0px",
                                  marginLeft: "20px",
                                }}
                                disabled={
                                  iconDisabled && (role !== "admin" || preview)
                                }
                              >
                                <NextIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        )}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      {/* <div>
        <DialogActions className={componentClasses.actions}>
          <Button
            onClick={closePreview}
            className={componentClasses.cancelBtn}
          >
            Close
          </Button>
        </DialogActions>
      </div> */}
    </Dialog>
  );
};

export default Preview;
