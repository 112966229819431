import React, { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import defaultImg from "../../assets/images/default_student_img.svg";
import update from "immutability-helper";

import moment from "moment-timezone";
import { useStyles } from "../Mentor/style";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";

const StudentList = ({
  studentsList,
  selectedStudent,
  setSelectedStudent,
  setActiveTable,
}) => {
  const classes = useStyles();
  const [studentName, setStudentName] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(studentsList);
  const isDarkMode = useSelector(selectDarkMode);

  const studentRefs = useRef([]);

  useEffect(() => {
    handleSearch();
  }, [studentName, studentsList]);

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const selectedIndex = filteredStudents.findIndex(
      (student) => student.studentId === selectedStudent
    );
    if (selectedIndex !== -1 && studentRefs.current[selectedIndex]) {
      studentRefs.current[selectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedStudent, filteredStudents]);

  const handleSearch = () => {
    if (studentName) {
      const filtered = studentsList.filter(
        (student) =>
          student.studentName
            .toLowerCase()
            .includes(studentName.toLowerCase()) ||
          student.studentEmail.toLowerCase().includes(studentName.toLowerCase())
      );
      setFilteredStudents(filtered);
    } else {
      setFilteredStudents(studentsList);
    }
  };

  const handleSelectStudent = (data, index) => {
    setSelectedStudent(data.studentId);
    setActiveTable("table1");
  };
  return (
    <Box className={`${classes.flex_col} ${classes.studentContainer}`}>
      <Grid className={classes.studentSearchBox}>
        <Grid
          className={`${classes.font14} ${classes.font600} ${classes.darkClr}`}
        >
          Your Students
        </Grid>
        <Grid
          className={classes.searchFieldContainer}
          style={{ border: isDarkMode && "1px solid #292929" }}
        >
          <input
            placeholder="Search students"
            className={classes.searchField}
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
            onKeyDown={handleSearchEnter}
          />
          <SearchOutlinedIcon
            fontSize="small"
            style={{ cursor: "pointer" }}
            onClick={handleSearch}
          />
        </Grid>
      </Grid>
      {filteredStudents.length > 0 ? (
        <Box className={`${classes.studentListBox} ${classes.flex_col}`}>
          {filteredStudents?.map((data, index) => {
            return (
              <Grid
                key={index}
                onClick={() => handleSelectStudent(data, index)}
                className={`${classes.flex_row} ${classes.alignItemsCenter} ${classes.studentCard}`}
                ref={(el) => (studentRefs.current[index] = el)}
                style={{
                  border: isDarkMode
                    ? "1px solid #292929"
                    : "1px solid #FCE2C5",
                  borderTop: index == 0 && "none",
                  background: selectedStudent === data.studentId && "#E98451",
                }}
              >
                <Grid
                  className={`${classes.flex_col} ${classes.alignContentCenter}`}
                >
                  <img
                    src={data?.profile || defaultImg}
                    alt="student"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                  />
                </Grid>
                <Grid
                  className={`${classes.col}`}
                  zeroMinWidth
                  style={{ marginLeft: "10px" }}
                >
                  <Grid
                    className={`${classes.font14} ${classes.font500} ${classes.StudentNameClr}`}
                    style={{
                      color:
                        (isDarkMode || selectedStudent === data.studentId) &&
                        "#FFF",
                    }}
                    noWrap
                  >
                    {data.studentName}
                  </Grid>
                  {data?.lastClass ? (
                    <Grid
                      className={`${classes.font12} ${classes.font400} ${classes.sortByText}`}
                      style={{
                        color: selectedStudent === data.studentId && "#FFF",
                      }}
                    >
                      Last class {data.lastClass}
                    </Grid>
                  ) : (
                    <Grid
                      className={`${classes.font12} ${classes.font400} ${classes.sortByText}`}
                      style={{
                        color:
                          (isDarkMode || selectedStudent === data.studentId) &&
                          "#FFF",
                      }}
                    >
                      -
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      ) : (
        <Box
          className={`${classes.studentListBox} ${classes.flex_col} ${classes.font500} ${classes.font14} ${classes.sortByText} ${classes.alignContentCenter} ${classes.textAlignCenter} mt-5`}
        >
          No Student Found
        </Box>
      )}
    </Box>
  );
};

export default StudentList;
