import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Box,
} from "@material-ui/core";
import { Multiselect } from "multiselect-react-dropdown";
import Tick from "../../assets/images/tickIcon.png";
import CloseIcon from "@material-ui/icons/Close";
import { API } from "../../Services/api-service";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxHeight: "446px",
    overflow: "auto",
    fontFamily: "Inter",
    maxWidth: 600,
    background: theme.palette.background.profileBackground,
    padding: theme.spacing(3),
  },
  heading: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Inter",
    marginBottom: "15px",
  },
  assignButton: {
    textAlign: "center",
    color: "#FFF",
    width: "128px",
    backgroundColor: "#E98451",
    fontWeight: 500,
    fontSize: "14px",
    padding: "7px 10px",
    height: "36px",
    margin: "20px auto",
    borderRadius: "4px",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#E98451",
    },
  },
  successModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  successContent: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    padding: "20px 20px 5px 20px",
    background: "transparent",
    borderRadius: "8px",
    outline: "none",
  },
  tickImage: {
    width: "50px",
    marginBottom: "20px",
  },
  successText: {
    fontWeight: 500,
    fontSize: "16px",
    marginBottom: "20px",
  },
  closeButton: {
    color: "#E98451",
    width: "128px",
    border: "1px solid #E98451",
    fontWeight: 500,
    fontSize: "14px",
    padding: "7px 10px",
    height: "36px",
    borderRadius: "4px",
    textTransform: "none",
  },
  btnBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingBottom: "25px",
  },
  closeBtnBox: {
    display: "flex",
    justifyContent: "right",
  },
}));

const AssignExercisePopup = ({
  open,
  onClose,
  student,
  assigned,
  setAssigned,
  studentId,
  classInfoId,
  getExerciseSummary,
  getRecentClasses,
  location,
}) => {
  const classes = useStyles();
  const [exercise, setExercise] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [displayExercise, setDisplayExercise] = useState([]);
  const [loader, setLoader] = useState(false);
  const isDarkMode = useSelector(selectDarkMode);

  const fetchExercises = async (searchText) => {
    try {
      const response = await API.get(
        `/mentor/exercise/search?text=${searchText}&match=exercise`
      );
      setExercise(response.data?.results);
    } catch (error) {
      console.error("Error fetching exercises:", error);
    }
  };

  const debouncedFetchExercises = useCallback(
    debounce(fetchExercises, 300),
    []
  );

  const onSearchExercise = (searchText) => {
    debouncedFetchExercises(searchText);
  };

  const onSelectExercise = (selectedList) => {
    setDisplayExercise(selectedList);
    const filteredExercises = selectedList.map(
      ({ exerciseId, exerciseTitle }) => ({
        exerciseId,
        exerciseTitle,
      })
    );
    setSelectedExercises(filteredExercises);
  };

  const onRemoveExercise = (selectedList) => {
    setDisplayExercise(selectedList);
    setSelectedExercises(selectedList);
  };

  const handleAssign = () => {
    setLoader(true);
    const updatedExercises = selectedExercises.map((item) => ({
      ...item,
      ...(classInfoId && { classInfoId }),
    }));
    let payload = {
      singleStudent: true,
      studentId,
      exercises: updatedExercises,
    };
    API.post(`/exercises/assignment/assign`, payload)
      .then((res) => {
        setAssigned(true);
        setLoader(false);
        setSelectedExercises([]);
        if (location == "DB") {
          getRecentClasses();
        } else {
          getExerciseSummary();
        }
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error(err.response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
      });
  };

  const handleSuccessClose = () => {
    onClose();
    setAssigned(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={assigned ? "xs" : "sm"}
        fullWidth
        sx={{
          background: isDarkMode ? "#1E1E1E" : "#FFFFFF",
          position: "relative",
        }}
      >
        <Box
          className={classes.closeBtnBox}
          style={{ padding: "5px", position: "absolute", right: 1 }}
        >
          <CloseIcon
            fontSize="small"
            onClick={() => {
              onClose();
            }}
            style={{ cursor: "pointer", color: !isDarkMode && "#2D2F39" }}
          />
        </Box>
        <Box className={classes.root}>
          {assigned ? (
            <Grid>
              <Grid container className={classes.successContent}>
                <img src={Tick} alt="Success" className={classes.tickImage} />
                <Grid
                  className={classes.successText}
                  style={{
                    color: isDarkMode ? "#D0CBC8" : "#2D2F39",
                  }}
                >
                  The exercise has been successfully assigned.
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                className={classes.heading}
                style={{
                  color: isDarkMode ? "#D0CBC8" : "#2D2F39",
                }}
              >
                Assign exercise to ‘{student}’
              </Grid>
              <Grid style={{ padding: "0px 25px" }}>
                <Multiselect
                  multiple
                  options={exercise}
                  displayValue="alias"
                  closeIcon="cancel"
                  idField="exerciseId"
                  onSelect={onSelectExercise}
                  onRemove={onRemoveExercise}
                  onSearch={onSearchExercise}
                  placeholder="Start typing and select"
                  selectedValues={displayExercise}
                  className={classes.selectTopics}
                  groupBy="catalogueTitle"
                  style={{
                    chips: { background: "#E98451" },
                    searchBox: {
                      border: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #ccc",
                      borderRadius: "4px",
                    },
                    inputField: {
                      color: isDarkMode ? "#FFF" : "#000",
                    },
                    optionContainer: {
                      backgroundColor: isDarkMode ? "#1E1E1E" : "#FFF",
                      border: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #ccc",
                    },
                    option: {
                      color: isDarkMode ? "#FFF" : "#000",
                    },
                  }}
                />
              </Grid>
            </>
          )}
          <Box>
            <Grid
              className={classes.btnBox}
              style={{
                marginTop: !assigned && "40px",
                paddingBottom: !assigned && "0px",
              }}
            >
              {assigned ? (
                <Button
                  onClick={handleSuccessClose}
                  className={classes.closeButton}
                >
                  Close
                </Button>
              ) : (
                <Button
                  onClick={handleAssign}
                  className={classes.assignButton}
                  disabled={loader || selectedExercises.length == 0}
                >
                  {loader ? "loading..." : "Assign"}
                </Button>
              )}
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AssignExercisePopup;
