import { decrypt } from "../../Core/utils/others";
import { API } from "../../Services/api-service";
import { isCoding, isQuestionnaire, isScratch, isWorksheet } from "../Home/exerciseHelper";
import setAuthCookies from "../../SharedComponents/setAuthCookies";


const getBrowserDetails = () => {
    let userAgent = navigator.userAgent;
    let browser = {};

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browser.name = "chrome";
      browser.version = `Chrome v${userAgent.split("Chrome/")[1]}`;
    } else if (userAgent.match(/firefox|fxios/i)) {
      browser.name = "firefox";
      browser.version = `Firefox v${userAgent.split("Firefox/")[1]}`;
    } else if (userAgent.match(/safari/i)) {
      browser.name = "safari";
      browser.version = `Safari v${userAgent.split("Safari/")[1]}`;
    } else if (userAgent.match(/edg/i)) {
      browser.name = "edge";
      browser.version = `Edg v${userAgent.split("Edg/")[1]}`;
    } else {
      browser.name = "No browser detected";
      browser.version = "No Version detected";
    }
    return browser;
  };
  const logWorkbench = (language, platform, exerciseId, submissionId) => {
    let browser = getBrowserDetails();
    API.post("/sandboxExecution", {
      language: language,
      status: "started",
      platform: platform,
      browser: browser.name,
      browserVersion: browser.version,
      vncId: null,
      executionId: null,
      frontEndSection: "tracker",
    })
      .then((res) => {
        const id = res.data.id;
        localStorage.setItem("exeId", res.data.id);
        window.open(
          `${process.env.REACT_APP_SCRATCH_PORTAL_URL}?executionId=${id}&exerciseId=${exerciseId}&submissionId=${btoa(submissionId)}`
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  export const handleExercise = (
    id,
    exerciseId,
    catalogueId,
    isDisabled = false,
    category,
    setCookie,
    type
  ) => {
    const sandboxUser = decrypt(localStorage.getItem("sandbox_user"));
    const domain = `${process.env.REACT_APP_SANDBOX_PORTAL_URL}`;
    if (type !== "exercise") {
      const url = `${domain}#/tracker/${id}`;
      window.open(url, "_self");
    }

    if (type === "exercise" && isDisabled) return;

    if (isQuestionnaire(category)) {
      window.open(`${domain}#/exercises/sections/${catalogueId}/${exerciseId}?from=tracker&submissionId=${btoa(id)}`);
    } else if (isWorksheet(category)) {
      const url = `${domain}#/exercises/maths/${catalogueId}/${exerciseId}?from=tracker&submissionId=${btoa(id)}`;
      window.open(url);
    } else if (isCoding(category)) {
      const url = `${domain}#/exercises/${catalogueId}/${exerciseId}?from=tracker&submissionId=${btoa(id)}`;
      window.open(url);
    } else if (isScratch(category)) {
      setAuthCookies(sandboxUser);
      setCookie("catalogueId", catalogueId, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("exerciseId", exerciseId, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("location", "exercise", {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("role", sandboxUser.role, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("fileId", undefined, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("fileName", exerciseId, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("type", "exerciseTracker", {
        path: "/",
        domain: ".codeyoung.com",
      });
      logWorkbench("scratch", "sandbox_ui", exerciseId, id);
    }
  };