import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Inter",
    height: "100vh",
    position: "relative",
    padding: "0px !important",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "25px",
    },
  },
  tableRoot: {
    fontFamily: "Inter",
  },
  leftRoot: {
    height: "768px",
    border: theme.palette.mentorDbLeftRoot,
    borderRadius: "12px",
    background: theme.palette.background.background0,
    padding: "15px",
  },
  font22: {
    fontSize: "22px",
  },
  font24: {
    fontSize: "24px",
  },
  pointsTableWrapper: {
    background: "#EDF2F4",
    padding: "10px",
  },
  font28: {
    fontSize: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  font20: {
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  font18: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  font14: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  font16: {
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  font12: {
    fontSize: "12px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  font10: {
    fontSize: "10px",
  },
  fontStyle: {
    lineHeight: "normal",
    fontStyle: "normal",
  },
  font800: {
    fontWeight: 800,
  },
  font700: {
    fontWeight: 700,
  },
  font600: {
    fontWeight: 600,
  },
  font500: {
    fontWeight: 500,
  },
  font400: {
    fontWeight: 400,
  },
  headingClr: {
    color: theme.palette.heading,
  },
  subHeadingClr: {
    color: theme.palette.body,
  },
  emptyStateTextClr: {
    color: theme.palette.viewDetailClr,
  },
  classTimeClr: {
    color: theme.palette.classTimeClr,
  },
  horizontalDivider: {
    background: theme.palette.dividerMD,
  },
  emtyStateCardTracker: {
    border: `1px solid ${theme.palette.dividerMD}`,
    borderRadius: "8px",
    height: "128px",
  },
  courseNameClr: {
    color: "#95B2B2",
  },
  textClrMoreCard: {
    color: theme.palette.sectionText,
  },
  fadedClr: {
    color: "#95B2B2",
  },
  btn: {
    textTransform: "none",
  },
  flex_row: {
    display: "flex",
  },
  flex_col: {
    display: "flex",
    flexDirection: "column",
  },
  flex_col_rev: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyAround: {
    justifyContent: "space-around",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  alignContentCenter: {
    alignItems: "center",
    alignContent: "center",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  alignContentleft: {
    alignItems: "left",
    alignContent: "left",
  },
  alignContentTop: {
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  justifyRight: {
    justifyContent: "right",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textAlignleft: {
    textAlign: "left",
  },
  innerdivider: {
    marginTop: "15px",
  },
  divider: {
    border: "1px solid #E6EFEF",
    marginTop: "30px",
  },
  dividerVertical: {
    border: "1px solid #E6EFEF",
    width: "10px",
    height: "100%",
  },
  btnClr: {
    color: "#FF712D",
  },
  width100: {
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTxt: {
    color: theme.palette.heading,
  },
  darkClr: {
    color: theme.palette.trackerHeading,
  },
  assignEerciseBtn: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "12px",
    height: "28px",
    textTransform: "none",
    background: "#E98451",
    color: "#FFF",
    width: "max-content",
    fontFamily: "Inter",
    "&:hover": {
      background: "#E98451",
      color: "#FFF",
    },
  },
  nofileFoundText: {
    marginTop: "16px",
  },
  StudentNameClr: {
    color: "#3D3D3D",
  },
  studentContainer: {
    height: "calc(100vh - 210px)",
    background: theme.palette.background.studentProgressBg,
    border: theme.palette.studentProgressBorder,
    padding: "10px 0px",
  },
  searchFieldContainer: {
    background: theme.palette.background.studentProgressBg,
    border: theme.palette.studentProgressBorder,
    borderRadius: "4px",
    width: "100%",
    height: "40px",
    padding: "0 5px 0 5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#A0A0A0",
    marginTop: "5px",
  },
  searchField: {
    border: "none",
    width: "100%",
    outline: "none",
    fontSize: "14px",
    fontWeight: 400,
    background: theme.palette.background.studentProgressBg,
    color: "#A0A0A0",
  },
  studentListBox: {
    maxHeight: "calc(100vh - 280px)",
    overflow: "auto",
    marginTop: "20px",
  },
  studentCard: {
    height: "64px",
    padding: "12px 10px 12px 12px",
    cursor: "pointer",
    borderBottom: "1px solid #FCE2C5",
  },
  studentSearchBox: {
    padding: "0px 12px",
  },
  cardDivider: {
    margin: "13px 0px 8px 0px",
  },
  headerRoot: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 15,
    fontFamily: "Inter",
  },
  headerCard: {
    height: "80px",
    borderRadius: "8px",
    padding: "10px",
  },
  trackerCtaRoot: {
    background: theme.palette.background.trackerCtaBG,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    height: "82px",
    borderRadius: "8px",
    fontFamily: "Inter",
  },
  trackerBtn: {
    padding: "12px 10px",
    color: "#FFF",
    background: "#E98451",
    height: "40px",
    width: "max-content",
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
    textTransform: "none",
    fontFamily: "Inter",
    "&:hover": {
      color: "#FFF",
      background: "#E98451",
    },
  },
  sortByText: {
    color: "#7A7A7A",
  },
  progressCardRoot: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 15,
    marginTop: "10px",
    maxHeight: "255px",
    overflow: "auto",
  },
  loaderRoot: {
    minHeight: "173px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  viewDetailsCta: {
    color: theme.palette.viewDetailClr,
    textTransform: "none",
    fontWeight: 500,
    fontSize: "11px",
    padding: "0px !important",
    fontFamily: "Inter",
  },
  studentCards: {
    background: theme.palette.background.studentProgressBg,
    border: theme.palette.studentProgressBorder,
    padding: "10px 8px",
    borderRadius: "8px",
    height: "73px",
    cursor: "pointer",
  },
  classDetailsRoot: {
    border: theme.palette.studentProgressBorder,
    background: theme.palette.background.studentProgressBg,
    height: "487px",
    maxHeight: "487px",
    borderRadius: "12px",
    fontFamily: "Inter",
  },
  recentClassRoot: {
    maxHeight: "316px",
    overflow: "auto",
  },
  recentlyOpenedCard: {
    border: theme.palette.studentProgressBorder,
    background: theme.palette.background.studentProgressBg,
    height: "80px",
    padding: "8px 12px",
    borderRadius: "8px",
    cursor: "pointer",
  },
  exerciseCardRoot: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: 15,
    marginTop: "10px",
    maxHeight: "173px",
    overflow: "auto",
  },
  root: {
    "& .MuiPaginationItem-root": {
      border: "1px solid #C4C0C0",
      color: "#7A7A7A",
      "&:hover": {
        backgroundColor: "#E98451",
        color: "#FFF",
        border: "1px solid #E98451",
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#E98451",
      color: "#FFF",
      border: "1px solid #E98451",
      "&:hover": {
        backgroundColor: "#E98451",
        color: "#FFF",
        border: "1px solid #E98451",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      color: "#7A7A7A",
    },
  },
  classDetailsStudentImage: {
    height: "40px",
    width: "40px",
    objectFit: "contain",
    borderRadius: "50%",
  },
  trackerImage: {
    height: "40px",
    width: "40px",
    marginRight: "15px",
    objectFit: "contain",
  },
  headerIcon: { height: "24px", width: "24px", objectFit: "contain" },
  subjectIcon: {
    height: "64px",
    width: "64px",
    objectFit: "contain",
    borderRadius: "50%",
  },
}));
