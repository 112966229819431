import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Frame from "react-frame-component";
import Format from "./format";
import { API } from "../../../Services/api-service";
import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";
import "./playground.css";
import Selected from "../../../assets/images/check_circle.png";

const IFrame = forwardRef((props, ref) => {
  const frameRef = useRef();
  const timeoutRef = useRef(null);
  const [height, setHeight] = useState(100);
  const [font, setFont] = useState(false);
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [answersList, setAnswersList] = useState({});

  const prevSelectedBoxRef = useRef(null);

  const handleClearAnswers = useCallback(() => {
    frameRef.current.contentDocument
      .querySelectorAll("[data-ans]")
      .forEach((node) => {
        if (node.getAttribute("type") === "latex") {
          node.value = "";
        } else {
          node.innerHTML = "";
        }
      });
  }, []);

  const displayCorrectAnswers = useCallback(() => {
    frameRef.current.contentDocument
      .querySelectorAll("[data-ans]")
      .forEach((node) => {
        if (
          node.getAttribute("type") === "multi-select" ||
          node.getAttribute("type") === "single-select"
        ) {
          let id = node.getAttribute("id");
          let questionId = node.getAttribute("question-id");
          let ansArray = props.properties?.questions[questionId];
          if (ansArray.includes(id)) {
            node.style.background = "rgb(202 246 202 / 50%)";
            node.style.border = "4px solid #14B814";
          } else {
            node.style.background = "rgb(255 255 255 / 50%)";
            node.style.border = "1px solid black";
          }
        } else if (node.getAttribute("type") === "latex" && !props.reattempt) {
          // let ansArray = props.properties.answers[node.id].split(":");
          const delimiter = ":";
          let ansArray = props.properties?.answers[node.id]?.slice(
            props.properties?.answers[node.id]?.indexOf(delimiter) +
              delimiter.length
          );
          node.innerHTML = ansArray;
          node.value = ansArray;
          node.style.background = "rgb(202 246 202 / 50%)";
          node.style.border = "4px solid #14B814";
        } else if (node.getAttribute("type") === "descriptive") {
          node.innerHTML = "";
        } else {
          node.innerHTML = props.properties?.answers[node.id];
          node.style.background = "rgb(202 246 202 / 50%)";
          node.style.border = "4px solid #14B814";
        }
      });
  }, []);

  const handleSubmittedAnswersDiv = (node, correctAnswers) => {
    if (correctAnswers && correctAnswers[node.id] === true) {
      node.style.background = "rgb(202 246 202 / 50%)";
      node.style.border = "4px solid #14B814";
    } else if (correctAnswers && correctAnswers[node.id] === false) {
      node.style.background = "rgb(246 214 202 / 50%)";
      node.style.border = "4px solid #ED6D5A";
    } else {
      node.style.background = "rgb(256 256 256 0.4)";
      node.style.border = "1px solid black";
    }
  };

  const handleMultipleChoice = (e) => {
    let node;
    if (e.target.tagName === "IMG") {
      node = e.target.parentNode;
    } else {
      node = e.target;
    }
    let userSubmission = node.getAttribute("user-submission");
    let selected = node.getAttribute("selected");
    const userSubmissionArray = userSubmission.split(":");

    // uncheck the selected box if it is a single select question

    if (selected === "no" && node.getAttribute("type") === "single-select") {
      let questionId = node.getAttribute("question-id");
      let questionAttribute = `[question-id="${questionId}"]`;
      frameRef.current.contentDocument
        .querySelectorAll(questionAttribute)
        .forEach((node) => {
          let submission = node.getAttribute("user-submission");
          if (submission) {
            let submissionArray = submission.split(":");
            if (submissionArray[1] === "yes") {
              handleChangedAnswers(
                node.id,
                `${submissionArray[0]}:no:${submissionArray[2]}`
              );

              node.setAttribute(
                "user-submission",
                `${submissionArray[0]}:no:${submissionArray[2]}`
              );
              node.setAttribute("selected", "no");
              node.style.background = "rgba(255, 255, 255, 0.4)";
              node.style.border = "1px solid black";
              let img = node.querySelector("img");
              if (img) {
                node.removeChild(img);
              }
            }
          }
        });
    }

    if (selected === "no") {
      handleChangedAnswers(
        node.id,
        `${userSubmissionArray[0]}:yes:${userSubmissionArray[2]}`
      );

      node.setAttribute(
        "user-submission",
        `${userSubmissionArray[0]}:yes:${userSubmissionArray[2]}`
      );
      node.setAttribute("selected", "yes");
      node.style.background = "#EBF4FF";
      node.style.border = "2px solid #0272F2";

      let img = document.createElement("img");
      img.src = Selected;
      img.alt = "selected";
      img.setAttribute("id", "selectedImg");
      img.style.height = "20px";
      img.style.width = "20px";
      img.style.float = "right";
      img.style.marginTop = "1%";
      img.style.marginRight = "1%";
      node.appendChild(img);
    } else {
      handleChangedAnswers(
        node.id,
        `${userSubmissionArray[0]}:no:${userSubmissionArray[2]}`
      );

      node.setAttribute(
        "user-submission",
        `${userSubmissionArray[0]}:no:${userSubmissionArray[2]}`
      );
      node.setAttribute("selected", "no");
      node.style.background = "rgba(255, 255, 255, 0.4)";
      node.style.border = "1px solid black";
      let img = node.querySelector("img");
      if (img) {
        node.removeChild(img);
      }
    }
  };

  const decode = (str) => {
    let txt = new DOMParser().parseFromString(str, "text/html");
    return txt.documentElement.textContent;
  };

  const handleMouseClick = (event, node) => {
    let val;
    if (
      typeof prevSelectedBoxRef.current === "object" &&
      prevSelectedBoxRef.current !== null
    ) {
      val = prevSelectedBoxRef.current.getAttribute("data-ans");
      if (val && val !== undefined) {
        prevSelectedBoxRef.current.style.border = "2px solid rgb(104, 214, 0)";
      } else {
        prevSelectedBoxRef.current.style.border = "1px solid #333";
      }
    }
    if (node !== null) {
      node.style.border = "2px solid #5A9EED";
    }
    prevSelectedBoxRef.current = node;
    props.handleBoxSelection(node);
  };

  const saveApi = (allAnswers) => {
    if (props.exerciseId && props.pageId) {
      API.post("/autoSave", {
        exerciseId: props.exerciseId,
        pageId: props.pageId,
        answers: allAnswers,
      })
        .then(() => {
          setAnswersList({});
        })
        .catch((err) => {
          const event = {
            message: "Auto save error!",
            level: Sentry.Severity.Info,
            extra: {
              exerciseId: props.exerciseId,
              err: err,
            },
          };
          Sentry.captureEvent(event);
        });
    }
  };

  const handleAutoSave = (event) => {
    const answerId = event.target.id;
    const answerText = event.target.innerHTML;
    const changedAnswer = {};
    changedAnswer[answerId] = answerText;

    handleChangedAnswers(answerId, answerText);

    setAnswersList((prevContent) => {
      let allChangedAnswers = JSON.parse(JSON.stringify(prevContent));
      allChangedAnswers = { ...allChangedAnswers, ...changedAnswer };

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        saveApi(allChangedAnswers);
      }, 1500);
      return allChangedAnswers;
    });
  };

  const handleChangedAnswers = (id, value) => {
    props.handleChangeAnswers(id, value);
  };
  const loadMathLiveScript = () => {
    return new Promise((resolve, reject) => {
      const iframeDocument = frameRef.current.contentDocument;

      if (!iframeDocument.querySelector('script[src*="mathlive.min.js"]')) {
        const mathScript = document.createElement("script");
        mathScript.src =
          "https://unpkg.com/mathlive@0.95.5/dist/mathlive.min.js";
        mathScript.onload = async () => {
          // Ensure proper registration of the component
          await new Promise((resolve) => setTimeout(resolve, 100));

          if (window.mathVirtualKeyboard) {
            window.mathVirtualKeyboard.targetOrigin = "*";
          }

          iframeDocument.defaultView.customElements
            .whenDefined("math-field")
            .then(() => {
              iframeDocument.body
                .querySelectorAll("math-field")
                .forEach((mf) => {
                  mf.virtualKeyboardPolicy = "manual";
                  // Optional for keyboard handling
                  mf.virtualKeyboardTargetOrigin = "*";

                  // Configure the virtual keyboard in the iframe
                  if (iframeDocument.defaultView.mathVirtualKeyboard) {
                    iframeDocument.defaultView.mathVirtualKeyboard.targetOrigin =
                      "*";
                  }
                });
            });
          resolve();
        };
        mathScript.onerror = () =>
          reject(new Error("Failed to load MathLive script"));

        iframeDocument.body.appendChild(mathScript);
      } else {
        resolve();
      }
    });
  };

  // Add this function to explicitly initialize the virtual keyboard
  const initializeVirtualKeyboard = (iframeDocument) => {
    // Set up keyboard in parent window
    if (window.mathVirtualKeyboard) {
      window.mathVirtualKeyboard.targetOrigin = "*";
    }

    // Set up keyboard in iframe
    if (iframeDocument.defaultView.mathVirtualKeyboard) {
      iframeDocument.defaultView.mathVirtualKeyboard.targetOrigin = "*";
    }
  };

  useEffect(() => {
    const frame = frameRef.current;

    const handleLoad = async () => {
      await loadMathLiveScript();
      const iframeDocument = frameRef.current.contentDocument;

      initializeVirtualKeyboard(iframeDocument);
      setHeight(frame.contentDocument.body.scrollHeight);
      setSize(props.styles?.size);
      setColor(props.styles?.color);
      if (props.clearAnswers) {
        handleClearAnswers();
      }
      const userAnswers = JSON.parse(props.exercise?.answers);

      frame.contentDocument.querySelectorAll("[data-ans]").forEach((node) => {
        if (node.getAttribute("type") === "latex") {
          node.style.padding = "0px";

          if (window.MathLive) {
            if (node.virtualKeyboardTargetOrigin !== undefined) {
              node.virtualKeyboardTargetOrigin = "*";
            }

            node.addEventListener("input", (ev) => {
              handleChangedAnswers(node.id, `${ev.target.value}`);
              node.setAttribute("user-submission", `${ev.target.value}`);
            });
            node.addEventListener("focusin", () => {
              window.mathVirtualKeyboard.show();
            });
            node.addEventListener("focusout", () => {
              window.mathVirtualKeyboard.hide();
            });
            node.addEventListener("click", (ev) => {
              ev.target.focus();
              window.mathVirtualKeyboard.show();
            });
            node.inlineShortcuts = {
              ...node.inlineShortcuts,
              $: "\\$",
            };

            node.mathModeSpace = "\\:";
            node.menuItems = [];
          }

          if (userAnswers && userAnswers[node.id]) {
            node.value = userAnswers[node.id];
          } else {
            node.value = "";
          }

          const styleElement = iframeDocument.createElement("style");
          styleElement.innerHTML = `
            math-field::part(virtual-keyboard-toggle) {
              color: red;
            }
            
            math-field::part(container) {
              height: 100% !important;
            }
            
            math-field::part(content) {
              display: flex;
              justify-content: center !important;
              align-items: center;
            }

            math-field::part(virtual-keyboard-toggle) {
              display: none;
            }
            
            math-field::part(menu-toggle) {
              display: none;
            }
            
            body > .ML__keyboard {
              z-index: 999999;
            }    
          `;
          iframeDocument.head.appendChild(styleElement);
        }
        if (node.getAttribute("type") === "open-ended") {
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.textAlign = "center";
        }
        node.style.overflowX = "hidden";
        node.style.overflowY =
          node.getAttribute("type") === "descriptive" ? "auto" : "hidden";
        if (props.role === "admin") {
          node.style.cursor = "pointer";
          if (node.getAttribute("data-ans")) {
            node.style.border = "2px solid #68D600";
          }
          node.setAttribute("contenteditable", false);
          node.addEventListener("click", (e) => handleMouseClick(e, node));
        } else {
          node.style.border = "1px solid black";
          if (
            node.getAttribute("type") === "multi-select" ||
            node.getAttribute("type") === "single-select"
          ) {
            node.setAttribute("contenteditable", false);
            node.addEventListener("click", handleMultipleChoice);
            node.style.cursor = "pointer";
          } else {
            node.style.cursor = "text";
            node.setAttribute("contenteditable", true);
          }

          if (node.getAttribute("type") === "descriptive") {
            node.addEventListener("input", handleAutoSave);
          }

          if (node.getAttribute("type") === "open-ended") {
            node.addEventListener("input", (ev) => {
              const decodedVal = decode(`${ev.target.innerHTML}`);
              handleChangedAnswers(node.id, decodedVal);
            });
          }

          node.style.fontSize = props.styles.size;
          node.style.color = props.styles.color;

          if (
            !props.answers &&
            Object.keys(props.exercise.answers ? props.exercise.answers : {})
              .length !== 0
          ) {
            if (
              node.getAttribute("type") === "multi-select" ||
              node.getAttribute("type") === "single-select"
            ) {
              node.innerHTML = "";
            } else if (
              userAnswers &&
              userAnswers[node.id] !== undefined &&
              node.getAttribute("type") !== "latex"
            ) {
              node.innerHTML = userAnswers[node.id];
            }
            handleSubmittedAnswersDiv(
              node,
              props?.exercise?.submission?.correctAnswers
            );
          }

          if (
            !props.clearAnswers &&
            (node.getAttribute("type") === "multi-select" ||
              node.getAttribute("type") === "single-select")
          ) {
            node.innerHTML = "";
          }

          if (
            !props.answers &&
            props.tab &&
            Object.keys(props.exercise.answers ? props.exercise.answers : {})
              .length !== 0
          ) {
            handleSubmittedAnswersDiv(
              node,
              props?.exercise?.submission?.correctAnswers
            );
          }
        }
      });

      if (props.answers) {
        displayCorrectAnswers();
      }
    };

    frame.addEventListener("load", handleLoad);

    return () => {
      frame.removeEventListener("load", handleLoad);
    };
  }, [
    props.clearAnswers,
    handleClearAnswers,
    props.answers,
    displayCorrectAnswers,
    props.tab,
    props.html,
  ]);

  useEffect(() => {
    const frame = frameRef.current;
    if (props.reattempt) {
      props.handleReattempt(false);
      frame.contentDocument.querySelectorAll("[data-ans]").forEach((node) => {
        node.style.background = "rgba(255, 255, 255, 0.4)";
        node.style.border = "1px solid black";
        if (node.getAttribute("type") === "latex") {
          node.value = "";
        } else {
          node.innerHTML = "";
        }

        if (
          node.getAttribute("type") === "multi-select" ||
          node.getAttribute("type") === "single-select"
        ) {
          node.setAttribute("selected", "no");
        }
      });
    }
  }, [props.reattempt]);

  useImperativeHandle(ref, () => ({
    handleFormat() {
      setFont(true);
    },

    handleSave() {
      const answersJson = {};
      let can = frameRef.current.contentDocument.querySelector("#canvas");
      let child = can.childNodes;
      child.forEach((item) => {
        if (item.id && item.id.includes("div")) {
          let val = item.getAttribute("data-ans");
          if (val && val !== null) {
            answersJson[item.id] = val;
            let ansArray = val.split(":");
            if (ansArray[0] === "select" || ansArray[0] === "select-one") {
              let questionId = ansArray.slice(-1);
              item.setAttribute("question-id", questionId);
              if (ansArray[0] === "select-one") {
                item.setAttribute(
                  "user-submission",
                  `select-one:no:${questionId}`
                );
                item.setAttribute("type", "single-select");
                item.setAttribute("selected", "no");
              } else {
                item.setAttribute("type", "multi-select");
                item.setAttribute("user-submission", `select:no:${questionId}`);
                item.setAttribute("selected", "no");
              }
            } else if (ansArray[0] === "@descriptive") {
              answersJson[item.id] = val;
              item.setAttribute("type", "descriptive");
              item.style.padding = "12px";
              item.style.overflowY = "auto";
            } else {
              item.setAttribute("type", "open-ended");
            }
          } else {
            answersJson[item.id] = "";
          }
        }
      });

      const formData = new FormData();
      var blob = new Blob([
        frameRef.current.contentDocument.querySelector("#root").outerHTML,
      ]);
      let filedata = new File([blob], `${props.exerciseId}.html`);
      formData.append("exerciseId", props.exerciseId);
      formData.append("answers", JSON.stringify(answersJson));
      formData.append("style", JSON.stringify({ size: size, color: color }));
      formData.append("isLatex", JSON.stringify(props.keyboard));
      formData.append("file", filedata);
      API.post(
        `/exercises/${props.exerciseId}/page/${
          props.pageId ? props.pageId : props.currentPageId
        }`,
        formData
      )
        .then((res) => {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.handleStatus("iframe", res.data.page?.properties?.answers);
          props.fetchExercise("preview");
          API.get(`/exercises/${props.exerciseId}/page/${props.currentPage}`)
            .then((res) => {
              if (res.data.success) {
                props.setWorkSheetData(res.data.page);
              }
            })
            .catch((err) => {
              console.log("unable to fecth data", err);
            });
        })
        .catch((e) => {
          toast.error("Error While Updating Page", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    },
  }));

  const handleFontSize = (value, color) => {
    setSize(value);
    setColor(color);
    frameRef.current.contentDocument
      .querySelectorAll("[data-ans]")
      .forEach((node) => {
        node.style.fontSize = value;
        node.style.color = color;
      });
    setFont(false);
  };

  const closeModel = () => {
    setFont(false);
  };

  return (
    <>
      {font && (
        <Format
          open={font}
          handleFontSize={handleFontSize}
          closeModalHandler={closeModel}
          size={size}
          fontColor={color}
        />
      )}
      <Frame
        id="worksheet"
        ref={frameRef}
        initialContent={`<!DOCTYPE html><html><head></head><body style="margin: 0">${props.html}</body></html>`}
        style={{
          height,
          width: "100%",
          border: 0,
          margin: 0,
        }}
      />
    </>
  );
});

export default IFrame;
