import { API } from "../../Services/api-service";
import setAuthCookies from "../../SharedComponents/setAuthCookies";
import jwt_decode from "jwt-decode";
import { decrypt } from "../../Core/utils/others";

const getBrowserDetails = () => {
  let userAgent = navigator.userAgent;
  let browser = {};

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browser.name = "chrome";
    browser.version = `Chrome v${userAgent.split("Chrome/")[1]}`;
  } else if (userAgent.match(/firefox|fxios/i)) {
    browser.name = "firefox";
    browser.version = `Firefox v${userAgent.split("Firefox/")[1]}`;
  } else if (userAgent.match(/safari/i)) {
    browser.name = "safari";
    browser.version = `Safari v${userAgent.split("Safari/")[1]}`;
  } else if (userAgent.match(/edg/i)) {
    browser.name = "edge";
    browser.version = `Edg v${userAgent.split("Edg/")[1]}`;
  } else {
    browser.name = "No browser detected";
    browser.version = "No Version detected";
  }
  return browser;
};

const logWorkbench = (language, platform, exerciseId) => {
  let browser = getBrowserDetails();
  API.post("/sandboxExecution", {
    language: language,
    status: "started",
    platform: platform,
    browser: browser.name,
    browserVersion: browser.version,
    vncId: null,
    executionId: null,
    frontEndSection: "exercises",
  })
    .then((res) => {
      const id = res.data.id;
      localStorage.setItem("exeId", res.data.id);
      window.location.href = `${process.env.REACT_APP_SCRATCH_PORTAL_URL}?executionId=${id}&exerciseId=${exerciseId}`;
    })
    .catch((e) => {
      console.error(e);
    });
};

export const isCoding = (category) => category === "Coding";
export const isWorksheet = (category) => category === "Worksheet";
export const isQuestionnaire = (category) => category === "Questionnaire";
export const isScratch = (category) => category === "Scratch";

export const handleExercise = (
  catalogId,
  exerciseName,
  exerciseId,
  language,
  exerciseAssignmentId,
  sandboxUser,
  setCookie,
  assignedToMeStatus,
  history,
  category
) => {
  API.post(`/exercises/viewed/${exerciseId}`, {
    type: exerciseAssignmentId ? null : "regular",
  })
    .then((res) => {})
    .catch((err) => {
      console.error(err);
    });

  if (isQuestionnaire(category)) {
    history.push(`/exercises/sections/${catalogId}/${exerciseId}`);
  } else if (isWorksheet(category)) {
    let URI = `/exercises/maths/${catalogId}/${exerciseId}`;
    if (assignedToMeStatus) {
      URI += `?assignedToMe=1`;
    }
    history.push(URI);
  } else if (isCoding(category)) {
    let URI = `/exercises/${catalogId}/${exerciseId}`;
    if (assignedToMeStatus) {
      URI += `?assignedToMe=1`;
    }
    history.push(URI);
  } else if (isScratch(category)) {
    setAuthCookies(sandboxUser);
    setCookie("catalogueId", catalogId, {
      path: "/",
      domain: ".codeyoung.com",
    });
    setCookie("exerciseId", exerciseId, {
      path: "/",
      domain: ".codeyoung.com",
    });
    setCookie("location", "exercise", {
      path: "/",
      domain: ".codeyoung.com",
    });
    setCookie("role", sandboxUser.role, {
      path: "/",
      domain: ".codeyoung.com",
    });
    setCookie("fileId", undefined, {
      path: "/",
      domain: ".codeyoung.com",
    });
    setCookie("fileName", exerciseId, {
      path: "/",
      domain: ".codeyoung.com",
    });
    setCookie("type", "newExercises", {
      path: "/",
      domain: ".codeyoung.com",
    });
    var tokens = decrypt(localStorage.getItem("sandbox_user"));
    const userInfo = jwt_decode(tokens.ACCESS_TOKEN).userInfo;

    setCookie("userId", userInfo.userId, {
      path: "/",
      domain: ".codeyoung.com",
    });
    logWorkbench("scratch", "sandbox_ui", exerciseId);
  }
};
