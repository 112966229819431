import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { useCookies } from "react-cookie";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "@material-ui/lab/Pagination";
import { MenuItem, FormControl, Select } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useStyles } from "../Mentor/style";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";
import AssignExercisePopup from "./AssignExercisePopup";
import { handleExercise } from "./exerciseHelper";
import { Tooltip, withStyles } from "@material-ui/core";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "#569090",
    border: "0.2px solid #CCCCCC",
    borderRadius: "8px",
    fontSize: 14,
    padding: "8px",
  },
}))(Tooltip);

const TableData = ({
  catalog,
  heading,
  header,
  data,
  topicSummary,
  onViewClick,
  show,
  setDays,
  days,
  summaryLoader,
  assigned,
  setAssigned,
  studentId,
  currentPage,
  totalPages,
  setCurrentPage,
  getExerciseSummary,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isDarkMode = useSelector(selectDarkMode);
  const [openPopup, setOpenPopup] = useState(false);
  const [cookies, setCookie] = useCookies([
    "catalogueId",
    "exerciseId",
    "role",
    "location",
    "fileId",
    "fileName",
    "type",
  ]);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const closePopup = () => {
    setOpenPopup(false);
  };

  const handleChange = (event) => {
    setCurrentPage(1);
    setDays(event.target.value);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const exerciseData = [
    {
      text: "Exercises Completed",
      count: data?.stats[0]?.completed || 0,
    },
    {
      text: "Exercises Assigned",
      count: data?.stats[0]?.assigned || 0,
    },
    {
      text: "Asssigned Pending",
      count: data?.stats[0]?.pending || 0,
    },
  ];

  const filter = [
    {
      text: "Last 7 days",
      val: 7
    },
    {
      text: "Last 15 days",
      val: 15
    },
    {
      text: "Last 30 days",
      val: 30
    },
    {
      text: "Last 90 days",
      val: 90
    },
  ];

  return (
    <Grid className={classes.tableRoot}>
      {show ? (
        <Grid
          className={`${classes.flex_row} ${classes.justifyBetween} ${classes.alignItemsCenter} mb-2`}
        >
          <Grid>
            <Grid
              className={`${classes.font600} ${classes.font14} mb-1`}
              style={{
                color: isDarkMode ? "#847871" : "#292929",
              }}
            >
              Student :
              <span
                className={`${classes.font500} ${classes.font14} ml-1`}
                style={{
                  color: isDarkMode ? "#D0CBC8" : "#7A7A7A",
                }}
              >
                {data?.student || "-"}
              </span>
            </Grid>
            <Grid
              className={`${classes.font600} ${classes.font14} mt-1`}
              style={{
                color: isDarkMode ? "#847871" : "#292929",
                marginLeft: "13px",
              }}
            >
              Grade :
              <span
                className={`${classes.font500} ${classes.font14} ml-1`}
                style={{
                  color: isDarkMode ? "#D0CBC8" : "#7A7A7A",
                }}
              >
                {data?.grade ? `Grade ${data?.grade}` : "-"}
              </span>
            </Grid>
            <Grid
              className={`${classes.font600} ${classes.font14} mt-1`}
              style={{
                color: isDarkMode ? "#847871" : "#292929",
                marginLeft: "4px",
              }}
            >
              Subject :
              <span
                className={`${classes.font500} ${classes.font14} ml-1`}
                style={{
                  color: isDarkMode ? "#D0CBC8" : "#7A7A7A",
                }}
              >
                {data?.subject?.length > 0
                  ? `${data?.subject.map((row) => capitalizeFirstLetter(row))}`
                  : "-"}
              </span>
            </Grid>
          </Grid>
          <Grid className={`${classes.flex_row} ${classes.alignItemsCenter}`}>
            {exerciseData?.map((data, index) => {
              return (
                <Grid
                  className={`${classes.flex_col} ${classes.alignItemsCenter} ${classes.textAlignCenter} mr-3`}
                  style={{
                    maxWidth: "74px",
                  }}
                >
                  <Grid
                    className={`${classes.font500} ${classes.font12}`}
                    style={{
                      color: isDarkMode ? "#847871" : "#7A7A7A",
                    }}
                  >
                    {data.text}
                  </Grid>
                  <Grid
                    className={`${classes.font24} ${classes.font600} ${classes.darkClr} mt-2`}
                    style={{
                      color: isDarkMode ? "#D0CBC8" : "#292929",
                    }}
                  >
                    {data.count}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <Divider
        style={{ marginTop: "20px" }}
        className={classes.horizontalDivider}
      />
      <Grid
        className={`${classes.flex_row} ${classes.justifyBetween} ${classes.alignItemsCenter} mt-4`}
      >
        <Grid
          className={`${classes.font600} ${classes.font16} ${classes.darkClr}`}
        >
          {heading}
        </Grid>
        {heading == "Exercises Summary" ? (
          <Grid
            className={`${classes.filterBox} ${classes.flex_row} ${classes.justifyBetween} ${classes.alignItemsCenter}`}
          >
            <span className={`${classes.filterText}`}>Show for:</span>
            <FormControl>
              <Select
                labelId="grade-label"
                value={`Last ${days} days`}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span style={{ fontStyle: "normal" }}>Select status</span>
                    );
                  }
                  return selected;
                }}
                inputProps={{ "aria-label": "Select status" }}
                className={classes.selectDropdown}
                sx={{
                  color: !isDarkMode ? "#292929" : "#D0CBC8",
                  width: "max-content",
                  border: "none",
                  height: "24px",
                  fontSize: "14px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  "& .MuiSvgIcon-root": {
                    color: !isDarkMode ? "#292929" : "#D0CBC8",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: !isDarkMode && "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: !isDarkMode && "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: !isDarkMode && "transparent",
                  },
                  "&.Mui-focused": {
                    outline: "none",
                    boxShadow: "none",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: isDarkMode ? "#414141" : "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        color: isDarkMode ? "#D0CBC8" : "#292929",
                        "&:hover": {
                          backgroundColor: isDarkMode && "#1E1E1E",
                        },
                      },
                    },
                  },
                }}
              >
                {filter.map((data, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={data.val}
                      sx={{
                        color: !isDarkMode ? "#292929" : "#D0CBC8",
                        fontSize: "12px",
                      }}
                    >
                      {data.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          heading == "Recent Assignments" &&
          topicSummary?.length > 0 && (
            <Grid>
              <Button
                className={classes.assignEerciseBtn}
                onClick={() => setOpenPopup(true)}
              >
                <span>Assign Exercises</span>
                <AddCircleOutlineOutlinedIcon
                  fontSize="small"
                  style={{
                    margin: "2px 0px 0px 4px",
                    height: "12px",
                    width: "12px",
                  }}
                />{" "}
              </Button>
            </Grid>
          )
        )}
      </Grid>
      <TableContainer
        style={{
          marginBottom: "20px",
          maxHeight: "370px",
          overflow: "auto",
          marginTop: "20px",
        }}
      >
        {summaryLoader ? (
          <Grid className={classes.loaderRoot}>
            <Spinner animation="border" variant="grey" />
          </Grid>
        ) : topicSummary?.length > 0 ? (
          <Table stickyHeader>
            {/* Table Header */}
            <TableHead>
              <TableRow style={{ backgroundColor: "#FAFBFF" }}>
                {header.map((headerName, index) => (
                  <TableCell
                    key={index}
                    align={
                      index == 0 || heading == "Recent Assignments"
                        ? "left"
                        : "center"
                    }
                    style={{
                      backgroundColor: isDarkMode ? "#2E2824" : "#FAFBFF",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      borderTop: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      fontWeight: "bold",
                      color: isDarkMode ? "#D0CBC8" : "#2761BA",
                      fontFamily: "Inter",
                    }}
                  >
                    {headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody style={{ color: isDarkMode ? "#ECEAE9" : "#7A7A7A" }}>
              {topicSummary?.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    align="left"
                    style={{
                      padding: "10px",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      color: isDarkMode ? "#ECEAE9" : "#7A7A7A",
                      fontFamily: "Inter",
                    }}
                  >
                    {heading == "Exercises Summary" ? (
                      <Grid>
                        {row.topic?.length > 42 ? (
                          <CustomTooltip title={row.topic}>
                            <Grid>{`${row.topic.slice(0, 42)}...`}</Grid>
                          </CustomTooltip>
                        ) : (
                          <Grid>{row?.topic}</Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid>
                        {row.exerciseTitle?.length > 22 ? (
                          <CustomTooltip title={row.exerciseTitle}>
                            <Grid>{`${row.exerciseTitle?.slice(
                              0,
                              22
                            )}...`}</Grid>
                          </CustomTooltip>
                        ) : (
                          <Grid>{row?.exerciseTitle}</Grid>
                        )}
                      </Grid>
                    )}
                  </TableCell>
                  <TableCell
                    align={heading == "Recent Assignments" ? "left" : "center"}
                    style={{
                      padding: "10px",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      color: isDarkMode ? "#ECEAE9" : "#7A7A7A",
                      fontFamily: "Inter",
                    }}
                  >
                    {heading == "Exercises Summary" ? (
                      row.completed
                    ) : row.concept && row.concept.length > 22 ? (
                      <CustomTooltip title={row.concept}>
                        <Grid>{`${row.concept.slice(0, 22)}...`}</Grid>
                      </CustomTooltip>
                    ) : (
                      <Grid>{row.concept}</Grid>
                    )}
                  </TableCell>
                  <TableCell
                    align={heading == "Exercises Summary" ? "center" : "left"}
                    style={{
                      padding: "10px",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      color: isDarkMode ? "#ECEAE9" : "#7A7A7A",
                      fontFamily: "Inter",
                    }}
                  >
                    {heading == "Exercises Summary"
                      ? row.assigned
                      : row.assignedAt}
                  </TableCell>
                  <TableCell
                    align={heading == "Exercises Summary" ? "center" : "left"}
                    style={{
                      padding: "10px",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      color:
                        row.status === "Completed"
                          ? "#3D8F4D"
                          : row.status === "Pending"
                          ? "#D33636"
                          : isDarkMode
                          ? "#ECEAE9"
                          : "#7A7A7A",
                      fontFamily: "Inter",
                    }}
                  >
                    {heading == "Exercises Summary" ? row.pending : row.status}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      padding: "10px",
                      color: "#FF712D",
                      cursor: "pointer",
                      borderBottom: isDarkMode
                        ? "1px solid #292929"
                        : "1px solid #EBF1FF",
                      fontSize: "14px",
                      fontFamily: "Inter",
                    }}
                    onClick={() => {
                      if (heading == "Exercises Summary") {
                        onViewClick(row);
                      } else {
                        if (row.completed) {
                          handleExercise(
                            row.submissionId,
                            row.exerciseId,
                            row.catalogId,
                            row.disable,
                            row.category,
                            setCookie,
                            "exercise"
                          );
                        }
                      }
                    }}
                  >
                    {heading == "Exercises Summary" ? (
                      <>
                        View
                        <ArrowForwardIosIcon style={{ fontSize: "14px" }} />
                      </>
                    ) : row.completed ? (
                      <OpenInNewIcon style={{ fontSize: "18px" }} />
                    ) : (
                      <OpenInNewIcon
                        style={{
                          fontSize: "18px",
                          color: "#CCCCCC",
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <center className={classes.emtyStateCardTracker}>
            <Grid
              className={`${classes.font500} ${classes.font14} ${classes.sortByText} mt-5`}
            >
              <Grid>
                {heading == "Exercises Summary" ? (
                  "No exercises summary found"
                ) : (
                  <Grid>
                    <Grid>No exercises assigned</Grid>
                    <Grid className="mt-2">
                      <Button
                        className={classes.assignEerciseBtn}
                        onClick={() => setOpenPopup(true)}
                      >
                        <span>Assign Exercises</span>
                        <AddCircleOutlineOutlinedIcon
                          fontSize="small"
                          style={{
                            margin: "2px 0px 0px 4px",
                            height: "12px",
                            width: "12px",
                          }}
                        />{" "}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </center>
        )}
      </TableContainer>
      {totalPages > 1 && heading == "Exercises Summary" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            marginTop: "15px",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            boundaryCount={1}
            siblingCount={3}
            color="primary"
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
            hideNextButton={true}
            hidePrevButton={true}
            classes={{ root: classes.root }}
          />
        </div>
      )}
      {openPopup && (
        <AssignExercisePopup
          open={openPopup}
          onClose={closePopup}
          student={data?.student}
          assigned={assigned}
          setAssigned={setAssigned}
          studentId={studentId}
          getExerciseSummary={getExerciseSummary}
          location="tracker-1"
        />
      )}
    </Grid>
  );
};

export default TableData;
