import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  editorWrapper: {
    margin: "30px auto",
  },

  buttonWrapper: {
    display: "flex",
    flexGrow: "1",
    margin: "15px auto",
  },
  radioWrapper: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    marginTop: "15px",
  },
  radioTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#474747",
  },
  radioText: {
    fontSize: "14px !important",
    fontWeight: "500",
    color: "#292929",
  },
  questionId: {
    fontSize: "14px",
    color: "#474747",
    fontWeight: "500",
  },
  cancel: {
    width: "100px",
    fontWeight: "700",
    color: "#FF712D",
    border: "1px solid #FF712D",
    borderRadius: "4px",
  },
  keyboardButton: {
    width: "300px",
    fontWeight: "700",
    color: "#FF712D",
    border: "1px solid #FF712D",
    borderRadius: "4px",
  },
  save: {
    width: "100px",
    fontWeight: "700",
    color: "#fff",
    background: "#FF712D",
    borderRadius: "4px",

    "&:hover": {
      backgroundColor: "#FF712D",
    },
  },

  textAreaInput: {
    width: "100%",
    minHeight: "8vh",
    padding: "8px",
  },

  firstTextAreaInput: {
    width: "calc(100% - 34px)",
    minHeight: "8vh",
    padding: "8px",
  },

  checkboxLabel: {
    fontSize: "16px !important",
  },
  answersWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  answerText: {
    color: "#474747",
    fontWeight: "500",
    fontSize: "16px",
    marginBottom: theme.spacing(1),
  },

  optionContainer: {
    display: "flex",
    alignItems: "center",
  },

  variationsWrapper: {
    display: "flex",
    justifyContent: "right",
    marginTop: 2,
    marginRight: 4,
  },

  variationBtn: {
    textTransform: "none",
    color: "#FF712D",
    padding: "0 8px 0 0",
    fontSize: "14px",
    fontWeight: "500",
  },
}));

const TextBoxEditor = ({
  selectedBox,
  textEditor,
  showTextEditor,
  handleBoxSelection,
  setShowAddPageBtn,
  handleVirtualKeyboard,
  keyboard,
  language,
  exercise,
  handleAnsVariations,
  ansVariations,
  setAnsVariations,
}) => {
  const classes = useStyles();
  toast.configure();
  const [type, setType] = useState("text");
  const [select, setSelect] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [openAnswer, setOpenAnswer] = useState("");
  const [boxId, setBoxId] = useState("");
  // const [options, setOptions] = useState([]);

  useEffect(() => {
    if (selectedBox) {
      setBoxId(selectedBox.getAttribute("id"));
      // handleVirtualKeyboard(false);
      if (selectedBox.getAttribute("data-ans") !== null) {
        let dataAns = selectedBox.getAttribute("data-ans");
        let ansArray = dataAns.split(":");
        if (ansArray[0] === "select" || ansArray[0] === "select-one") {
          setType("multiple");
          if (ansArray[0] === "select") {
            setSelect("select");
          } else {
            setSelect("select-one");
          }
          setAnswer(ansArray[1]);
          setQuestionId(ansArray[2]);
        } else if (ansArray[0] === "latex") {
          setType("virtual");
        } else if (ansArray[0] === "@descriptive") {
          setType("descriptive");
        } else {
          setType("text");
          setOpenAnswer(ansArray[0]);
        }
      } else {
        setType("text");
        setSelect("");
        setAnswer("");
        setQuestionId("");
        setOpenAnswer("");
      }
    }
  }, [selectedBox]);

  useEffect(() => {
    // console.log(keyboard);
  }, [keyboard]);

  const onTypeChange = (e) => {
    setType(e.target.value);
  };

  const onChooseSelect = (e) => {
    setSelect(e.target.value);
  };

  const onChooseAnswer = (e) => {
    setAnswer(e.target.value);
  };

  const handleQuestionId = (e) => {
    setQuestionId(e.target.value);
  };

  const handleSave = () => {
    let ans;
    if (type === "text") {
      ans = openAnswer;
      setAnsVariations(ansVariations);
    } else if (type === "descriptive") {
      ans = "@descriptive";
    } else {
      ans = `${select}:${answer}:${questionId}`;
    }
    selectedBox.setAttribute("data-ans", ans);
    if (ans.length !== 0) {
      selectedBox.style.border = "2px solid #68D600";
    } else {
      selectedBox.style.border = "1px solid #333";
    }
    toast.success("Answer saved successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    showTextEditor(false);
    setShowAddPageBtn(true);
    handleBoxSelection(null);
  };

  const handleCancel = () => {
    showTextEditor(false);
    setShowAddPageBtn(true);
    handleBoxSelection(null);
  };

  const handleOpenAnswer = (e) => {
    setOpenAnswer(e.target.value);
  };

  const handleKeyboard = (boxId, checked) => {
    handleVirtualKeyboard(boxId, checked);
  };

  const handleOptionChange = (index, value) => {
    setAnsVariations((prev) => {
      const currentVariations = [...(prev[boxId] || [])];
      currentVariations[index] = value;
      return {
        ...prev,
        [boxId]: currentVariations,
      };
    });
  };

  // Add a new variation
  const handleAddOption = () => {
    if (!boxId) return;

    setAnsVariations((prev) => {
      const currentVariations = prev[boxId] || [];
      return {
        ...prev,
        [boxId]: [...currentVariations, ""],
      };
    });
  };

  // Delete a variation
  const handleRemoveOption = (indexToRemove) => {
    setAnsVariations((prev) => {
      const currentVariations = [...(prev[boxId] || [])];
      currentVariations.splice(indexToRemove, 1);
      return {
        ...prev,
        [boxId]: currentVariations,
      };
    });
  };

  const getCurrentVariations = () => {
    return boxId ? ansVariations[boxId] || [] : [];
  };

  return (
    <div style={{ minHeight: type === "text" ? "250px" : "450px" }}>
      {selectedBox !== null && selectedBox.length !== 0 && textEditor && (
        <Grid container xs={12} className={classes.editorWrapper}>
          <Grid item xs={12}>
            <Box
              sx={{
                color: "#474747",
                textAlign: "center",
                fontWeight: "700",
              }}
            >
              TEXT BOX EDITOR
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.radioWrapper}>
            <FormControl>
              <FormLabel id="choose-type" className={classes.radioTitle}>
                Choose type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="choose-type"
                name="row-radio-buttons-group"
                onChange={onTypeChange}
                value={type}
              >
                <FormControlLabel
                  value="text"
                  control={<Radio size="small" />}
                  label="Text"
                  classes={{ label: classes.radioText }}
                />
                <FormControlLabel
                  value="multiple"
                  control={<Radio size="small" />}
                  label="Multiple Choice"
                  classes={{ label: classes.radioText }}
                />
                {language === "maths" && (
                  <FormControlLabel
                    value="virtual"
                    control={<Radio size="small" />}
                    label="Virtual Keyboard"
                    classes={{ label: classes.radioText }}
                  />
                )}
                {language !== "maths" && (
                  <FormControlLabel
                    value="descriptive"
                    control={<Radio size="small" />}
                    label="Descriptive"
                    classes={{ label: classes.radioText }}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          {type === "multiple" ? (
            <>
              <Grid item xs={12} className={classes.radioWrapper}>
                <FormControl>
                  <FormLabel id="choose-select" className={classes.radioTitle}>
                    Choose answer type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="choose-select"
                    name="row-radio-buttons-group"
                    onChange={onChooseSelect}
                    value={select}
                  >
                    <FormControlLabel
                      value="select-one"
                      control={<Radio size="small" />}
                      label="Single"
                      classes={{ label: classes.radioText }}
                    />
                    <FormControlLabel
                      value="select"
                      control={<Radio size="small" />}
                      label="Multi answer"
                      classes={{ label: classes.radioText }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.radioWrapper}>
                <FormControl>
                  <FormLabel id="choose-answer" className={classes.radioTitle}>
                    Choose answer
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="choose-answer"
                    name="row-radio-buttons-group"
                    onChange={onChooseAnswer}
                    value={answer}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label="Correct"
                      classes={{ label: classes.radioText }}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label="Incorrect"
                      classes={{ label: classes.radioText }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.radioWrapper}>
                <div className={classes.col}>
                  <Box className={classes.questionId}>Question ID</Box>
                  <TextField
                    variant="outlined"
                    className={classes.inputField}
                    size="small"
                    value={questionId}
                    onChange={handleQuestionId}
                  />
                </div>
              </Grid>
            </>
          ) : type === "text" ? (
            <Grid item xs={12}>
              <div className={classes.answersWrapper}>
                <Box className={classes.answerText}>Answer</Box>
                <TextareaAutosize
                  required={true}
                  value={openAnswer}
                  onChange={handleOpenAnswer}
                  placeholder="Variation 1"
                  className={classes.firstTextAreaInput}
                />
                {getCurrentVariations().map((option, index) => (
                  <div key={index} className={classes.optionContainer}>
                    <TextareaAutosize
                      value={option}
                      onChange={(e) =>
                        handleOptionChange(index, e.target.value)
                      }
                      placeholder={`Variation ${index + 1 + 1}`}
                      className={classes.textAreaInput}
                    />
                    <DeleteOutlineOutlinedIcon
                      onClick={() => handleRemoveOption(index)}
                      style={{
                        color: "#E76B69",
                        cursor: "pointer",
                        margin: "auto 0 auto 10px",
                      }}
                    />
                  </div>
                ))}
                <Grid className={classes.variationsWrapper}>
                  <Button
                    className={`${classes.variationBtn}`}
                    onClick={handleAddOption}
                  >
                    <strong>Add Variartions +</strong>
                  </Button>
                </Grid>
              </div>
            </Grid>
          ) : type === "virtual" ? (
            <Grid item xs={12} className={classes.radioWrapper}>
              <div className={classes.col}>
                <FormGroup>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    control={
                      <Checkbox
                        checked={keyboard[boxId]}
                        onChange={(e) =>
                          handleKeyboard(boxId, e.target.checked)
                        }
                      />
                    }
                    label="Add Equations through Virtual Keyboard"
                  />
                </FormGroup>
              </div>
            </Grid>
          ) : (
            ""
          )}
          {type !== "virtual" && (
            <Grid
              container
              xs={12}
              spacing={2}
              justify="center"
              className={classes.buttonWrapper}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  className={`${classes.cancel}`}
                  onClick={handleCancel}
                  style={{ cursor: "pointer" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={`${classes.save}`}
                  onClick={handleSave}
                  style={{ cursor: "pointer" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default TextBoxEditor;
